import {
  SELECTING_PATH, RESET_PATH, SET_PATH, HIERARCHY
} from "../actions";

const initialState = {
  id: undefined,
  path: [],
  selecting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {

    case HIERARCHY + SET_PATH: {

      return {
        ...state,
        id: action.payload.id,
        path: action.payload.path,
      };
    }

    case HIERARCHY + RESET_PATH: {
      return {
        ...state,
        id: undefined,
        path: [],
      };
    }

    case HIERARCHY + SELECTING_PATH: {
      return {
        ...state,
        selecting: action.payload,
      }
    }

    default:
      return state;
  }
};
