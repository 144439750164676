import { createTheme } from '@material-ui/core';
import { createTheme as createThemeMui } from '@mui/material';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import overrides from './overrides';

const baseTheme = {
  palette,
  typography,
  breakpoints,
  overrides
};

//should make baseTheme fit the ThemeOptions type.
//@ts-ignore
export const theme = createTheme(baseTheme);
//@ts-ignore
export const themeMui = createThemeMui(baseTheme);

type MuiTheme = typeof themeMui

//This can probably be removed when migration from material-ui to mui is complete
declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends MuiTheme {}
}