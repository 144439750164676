const env = process.env.REACT_APP_ENV;

const featureToggles: any = {
    FEATURE_TOGGLE_ANALYTICS_DASHBOARD: {
        development: true,
        dev: true,
    },
    FEATURE_TOGGLE_METRICS_DASHBOARD: {
        development: true,
        dev: true,
        staging: true,
        demo: true,
        production: true,
    },
}

export const featureToggle = (toggle: string): boolean => {
    const ft = featureToggles[toggle];
    return !!env && !!ft ? ft[env] : false;
}
