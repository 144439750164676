import * as actionTypes from "actions/actionTypes";
import {ACTION_PREFIX} from "modules/notifications/actions";
import {statuses} from "store/constants";

const initialState = {
  items: [],
  filteredItems: [],
  lastChecked: null,
  numTotal: null,
  numUnread: null,
  status: statuses.IDLE,
  meta: null
};

const filterItems = (items, lastChecked) => {
  return items
    .filter(m => !m.read)
    .filter(m => !lastChecked || m.metaData.createdAt > lastChecked);
};

const calcNumUnread = (items, lastChecked) => {
  return items
    .filter(m => !m.read)
    .filter(m => !lastChecked || m.metaData.createdAt > lastChecked)
    .length;
};

export default (state = initialState, action) => {
  switch (action.type) {

    case ACTION_PREFIX + actionTypes.LOAD:
      return {
        ...state,
        status: statuses.LOADING,
        meta: ""
      };

    case ACTION_PREFIX + actionTypes.LOADED:
      return {
        ...state,
        status: statuses.LOADED,
        items: action.payload,
        filteredItems: filterItems(action.payload, state.lastChecked),
        numTotal: action.payload.length,
        numUnread: calcNumUnread(action.payload, state.lastChecked),
        meta: ""
      };

    case ACTION_PREFIX + actionTypes.LOAD_FAILED:
      return {
        ...state,
        status: statuses.FAILED,
        meta: action.payload
      };

    case ACTION_PREFIX + "_LOAD_LAST_CHECKED":
      return {
        ...state,
        lastChecked: action.payload
      };

    case ACTION_PREFIX + "_UPDATE_LAST_CHECKED":
      return {
        ...state,
        lastChecked: action.payload,
        numUnread: calcNumUnread(state.items, action.payload),
      };

    default:
      return state;
  }
};
