export const REGION_FILTER = "REGION_FILTER";
export const SET_REGION = "SET_REGION";
export const WITH_SELECTED_REGIONS = "WITH_SELECTED_REGIONS"
export const RESET_REGIONS = "RESET_REGIONS";
export const GENERATE_REGION_OBJECT = "GENERATE_REGION_OBJECT";

export const setRegion = (id) => dispatch => {
  dispatch({
    type: REGION_FILTER + SET_REGION,
    payload: id,
  });
}

export const resetRegions = () => (dispatch, getState) => {
  dispatch({
    type: REGION_FILTER + RESET_REGIONS,
    payload: {
      regionsCountries: getState().settings.regionsCountries,
    }
  });
}

export const generateRegionObject = () => (dispatch, getState) => {
  dispatch({
    type: REGION_FILTER + GENERATE_REGION_OBJECT,
    payload: {
      regionsCountries: getState().settings.regionsCountries,
    }
  });
}