import { User as UserType} from "@jetseafoodas/sfp-company-types";
import * as actionTypes from "actions/actionTypes";
import {ACTION_PREFIX_USERS, ACTION_PREFIX} from "modules/management/actions/usersActions";
import {statuses, filterRoles, companyStatuses} from "store/constants";
import { PayloadAction } from '@reduxjs/toolkit'

const initialState: UserFilterState = {
  items: [],
  filteredItems: [],
  filter: {
    inputValue: '',
    chips: []
  },
  status: null,
  meta: null,
  user: null,
};

//TODO: maybe add onlineStatus and companyStatus to User from sfp-company-types?
interface User extends UserType {
  onlineStatus: string,
  companyStatus: string,
  fullName: string,
  companyName: string,
  userDeleted?: boolean
}

interface Filter {
  chips: string[],
  inputValue: string
}

interface UserFilterState {
  items: User[],
  filteredItems: User[],
  filter: Filter,
  status: string | null,
  meta: any, //string?
  user: User | null
}

const filterItems = (items: User[], filter: Filter) => {
  const filterChipsRoles = filter.chips.filter((chip: string): boolean => filterRoles.indexOf(chip) >= 0);
  const filterChipsStatus = filter.chips.filter((chip: string): boolean => ['Online', 'Offline', 'Disabled', 'Deleted'].indexOf(chip) >= 0);
  const filterChipsCompanyStatuses = filter.chips.filter((chip: string): boolean => companyStatuses.indexOf(chip) >= 0);
  let filterChipsFree = filter.chips.filter((chip: string): boolean => filterChipsRoles.indexOf(chip) < 0 &&
    filterChipsStatus.indexOf(chip) < 0 &&
    filterChipsCompanyStatuses.indexOf(chip) < 0
  );

  if (filter && filter.inputValue) {
    filterChipsFree.push(filter.inputValue);
  }

  return items
    .filter((item: User): boolean => {

      let ret = true;

      if (filterChipsRoles.length > 0) {
        ret = false;
      }
      filterChipsRoles.forEach((chip: string): void => {
        if (item?.roleId?.toLowerCase().indexOf(chip.toLowerCase()) >= 0) {
          ret = true;
        }
      });

      if (ret) {
        if (filterChipsStatus.length > 0) {
          ret = false;
        }
        filterChipsStatus.forEach((chip: string): void => {
          if ((chip === 'Disabled' && item.userDisabled) || (chip === 'Deleted' && item.userDeleted) ||
            (chip !== 'Disabled' && item.onlineStatus.toLowerCase().indexOf(chip.toLowerCase()) >= 0)) {
            ret = true;
          }
        });
      }

      if (ret) {
        if (filterChipsCompanyStatuses.length > 0) {
          ret = false;
        }
        filterChipsCompanyStatuses.forEach((chip: string): void => {
          if (item.companyStatus && item.companyStatus.toLowerCase().indexOf(chip.toLowerCase()) >= 0) {
            ret = true;
          }
        });
      }

      filterChipsFree.forEach(chip => {
        if (!(
          (item.fullName && item.fullName.toLowerCase().indexOf(chip.toLowerCase()) >= 0) ||
          (item.email && item.email.toLowerCase().indexOf(chip.toLowerCase()) >= 0) ||
          (item.phone && item.phone.toLowerCase().indexOf(chip.toLowerCase()) >= 0) ||
          (item.companyName && item.companyName.toLowerCase().indexOf(chip.toLowerCase()) >= 0)
        )) {
          ret = false;
        }
      });

      return ret;
    });
};

export default (state: UserFilterState = initialState, action: PayloadAction<Partial<UserFilterState>>): UserFilterState => {
  switch (action.type) {

    case ACTION_PREFIX_USERS + actionTypes.LOAD:
      return {
        ...state,
        status: statuses.LOADING,
        meta: ""
      };

    case ACTION_PREFIX_USERS + actionTypes.LOADED:
      return {
        ...state,
        status: statuses.LOADED,
        items: action.payload.items ?? initialState.items,
        meta: ""
      };

    case ACTION_PREFIX_USERS + actionTypes.LOAD_FAILED:
      return {
        ...state,
        status: statuses.FAILED,
        meta: action.payload.meta ?? initialState.meta
      };

    case ACTION_PREFIX_USERS + "_UPDATE_FILTER":
      return {
        ...state,
        filter: action.payload.filter ?? initialState.filter
      };

    case ACTION_PREFIX_USERS + "_FILTER":
      return {
        ...state,
        filteredItems: filterItems(state.items, state.filter)
      };

    case ACTION_PREFIX + actionTypes.LOAD:
      return {
        ...state,
        status: statuses.LOADING,
        user: null,
        meta: ""
      };

    case ACTION_PREFIX + actionTypes.LOADED:
      return {
        ...state,
        status: statuses.LOADED,
        user: action.payload.user ?? initialState.user,
        meta: ""
      };

    case ACTION_PREFIX + actionTypes.LOAD_FAILED:
      return {
        ...state,
        status: statuses.FAILED,
        meta: action.payload.meta ?? initialState.meta
      };

    case ACTION_PREFIX + actionTypes.SAVED:
      return {
        ...state,
        status: statuses.LOADED,
        user: action.payload.user ?? initialState.user,
        meta: ""
      };

    default:
      return state;
  }
};
