import { lazy } from 'react';

export const routes = [
  {
    path: '/settings/user',
    exact: true,
    component: lazy(() => import('./UserSettings'))
  },
  {
    path: '/settings/user/:tab',
    exact: true,
    component: lazy(() => import('./UserSettings'))
  },
  {
    path: '/settings/currency',
    exact: true,
    component: lazy(() => import('./CurrencySettings'))
  },
  {
    path: '/settings/contacts',
    exact: true,
    component: lazy(() => import('./ContactSettings'))
  }
];
