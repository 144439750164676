import {lazy} from 'react';

export const routes = [
    {
        path: '/o/:id',
        exact: true,
        component: lazy(() => import('./ListingDetailsOpen'))
    },
];

