import {colors} from "@material-ui/core";

export default {
  root: {
    backgroundColor: colors.grey[50],
  },
  notchedOutline: {
    borderColor: 'rgba(0,0,0,0.0)'
  }
};
