import React, { useState, useLayoutEffect } from 'react'
import moment from 'moment';

const APP_LOCALE = "appLocale";

const DEFAULT_LOCALE = 'en-GB';

export const SUPPORTED_LOCALES = {
  'da': { country: 'Denmark' },
  'de': { country: 'Germany' },
  'en-CA': { country: 'Canada' },
  'en-GB': { country: 'United Kingdom' },
  'en-IL': { country: 'Ireland' },
  'es': { country: 'Spain' },
  'et': { country: 'Estonia' },
  'fi': { country: 'Finland' },
  'fr': { country: 'France' },
  'il': { country: 'Italy' },
  'is': { country: 'Iceland' },
  'jp': { country: 'Japan' },
  'lt': { country: 'Lithuania' },
  'lv': { country: 'Latvia' },
  'nb': { country: 'Norway' },
  'nl': { country: 'Netherlands' },
  'pl': { country: 'Poland' },
  'pt': { country: 'Portugal' },
  'ru': { country: 'Russia' },
  'en-US': { country: 'USA' },
  'sv': { country: 'Sweden' },
  'zh-cn': { country: 'China' },
};

export const LocaleContext = React.createContext({
  locale: DEFAULT_LOCALE
});

const getLocaleFromBrowser = () => {
  const browserLocale = window.navigator.language;
  let momentLocale = SUPPORTED_LOCALES[browserLocale];
  if (momentLocale) {
    return browserLocale;
  }
  momentLocale = SUPPORTED_LOCALES[browserLocale.split('-')[0]];
  if (momentLocale) {
    return browserLocale.split('-')[0];
  }
  return DEFAULT_LOCALE;
};

const getAppLocale = () => {
  const appLocale = window.localStorage.getItem(APP_LOCALE);
  return appLocale && SUPPORTED_LOCALES[appLocale] ? appLocale : undefined;
};

const setAppLocale = (locale) => {
  window.localStorage.setItem(APP_LOCALE, locale);
};

const updateMoment = (locale) => {
  const momentLocale = locale.toLowerCase();
  if (momentLocale === 'en' || momentLocale === 'en-us') {
    moment.locale(momentLocale);
  } else {
    moment.locale(momentLocale, require(`moment/locale/en-gb`));
    moment.locale(momentLocale, require(`moment/locale/${momentLocale}`));
  }
  if (momentLocale.startsWith('en')) {
    moment.updateLocale(momentLocale, {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'seconds',
        ss: '%ss',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '1M',
        MM: '%dM',
        y: '1Y',
        yy: '%dY'
      }
    });
  } else if (momentLocale === 'nb') {
    moment.updateLocale(momentLocale, {
      relativeTime: {
        future: 'om %s',
        past: '%s siden',
        s: 'sekunder',
        ss: '%ss',
        m: '1m',
        mm: '%dm',
        h: '1t',
        hh: '%dt',
        d: '1 dag',
        dd: '%d dager',
        M: 'en måned',
        MM: '%d måneder',
        y: 'ett år',
        yy: '%d år'
      }
    });
  }
};

export const LocaleContextProvider = (props) => {

  const [locale, setLocale] = useState(getAppLocale() || getLocaleFromBrowser());
  const [formatting, setFormatting] = useState({
    thousandSeparator: ",",
    decimalSeparator: "."
  });

  useLayoutEffect(() => {
    const selectedLocale = getAppLocale();
    if (selectedLocale) {
      setLocale(selectedLocale);
      updateMoment(selectedLocale);
      updateFormatting(selectedLocale);
    } else {
      setAppLocale(locale);
      updateMoment(locale);
      updateFormatting(locale);
    }
  }, [locale]);

  const updateFormatting = (locale) => {
    const parts = new Intl.NumberFormat(locale).formatToParts(1000.99);
    const thousandSeparator = parts.find(p => p.type ==="group");
    const newFormatting = {
      thousandSeparator: thousandSeparator ? thousandSeparator.value : "",
      decimalSeparator: parts.find(p => p.type ==="decimal").value
    };
    setFormatting(newFormatting);
  };

  const switchLocale = (locale) => {
    setAppLocale(locale);
    setLocale(locale);
    updateMoment(locale);
    updateFormatting(locale);
  };

  return (
    <LocaleContext.Provider value={{
      locale,
      formatting,
      switchLocale
    }}>
      {props.children}
    </LocaleContext.Provider>
  )
};

export const LocaleConsumer = LocaleContext.Consumer;
