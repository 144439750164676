import axios from "axios";
import { ListingsClient } from "@jetseafoodas/sfp-listings-client";
import { CompanyClient, HttpClientOptions } from "@jetseafoodas/sfp-company-client";
import * as authService from "./auth/auth.service";
import * as authActions from "actions/authActions";
import { dispatch } from "../App";

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`,
  timeout: 60000
});

axiosInstance.interceptors.response.use(undefined, err => {
  const { response } = err;
  if (response?.status === 401) {
    dispatch(authActions.checkTokenLocally());
  }
  return Promise.reject(err);
});

export const getConfig = () => ({
  headers: {
    "x-access-token": authService.getToken(),
    "x-req-code": authService.getRequestCode(),
    Accept: "application/json"
  }
});

const selectData = response => response.data;

export const get = url => axiosInstance.get(url, getConfig()).then(selectData);
export const post = (url, data) => axiosInstance.post(url, data, getConfig()).then(selectData);

export const getFile = url => axiosInstance({
  method: "get",
  url: url,
  headers: {
    "x-access-token": authService.getToken()
  },
  responseType: "arraybuffer"
});

const options = new HttpClientOptions(axiosInstance, getConfig);
export const companyClient = new CompanyClient(options);
export const listingsClient = new ListingsClient(options);
