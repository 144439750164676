const flatToMap = (list, key) => {
  if(!list) return list;
  const queue = [...list.filter(e => !e[key])];
  const idToList = {default: new Set(["default",...subList(list, {id: undefined}).map(e => e.id)])};
  while (queue.length > 0) {
    const curr = queue.pop();
    const currList = subList(list, curr);
    idToList[curr.id] = new Set([curr.id, ...currList.map(e => e.id)]);
    queue.push(...currList);
  }
  return idToList;
}
const subList = (list, elem) => {
  const currList = list.filter(e => e.parent === elem.id);
  return currList.concat(...currList.map(e => subList(list, e)));
}

const flatToTree = (list, key) => {
  if(!list) return list;
  const tree = {};
  for (const top of list.filter(e => !e[key])) {
    tree[top.id] = subTree(list, top);
  }
  return tree;
}
const subTree = (list, elem) => {
  const children = list.filter(e => !!e.parent && e.parent === elem.id);
  return children.map(e => [e.id, subTree(list, e)])
      .reduce((prev,[key, val]) => {prev[key]=val; return prev;},elem)
}

export {flatToTree, flatToMap}