import { lazy } from 'react';

export const routes = [
  {
    path: '/management/companies',
    exact: true,
    component: lazy(() => import('./CompanyManagementList'))
  },
  {
    path: '/management/companies/:id',
    exact: true,
    component: lazy(() => import('./CompanyManagementDetails'))
  },
  {
    path: '/management/companies/:id/:tab',
    exact: true,
    component: lazy(() => import('./CompanyManagementDetails'))
  },
  {
    path: '/management/users',
    exact: true,
    component: lazy(() => import('./UserManagementList'))
  },
  {
    path: '/management/users/:id',
    exact: true,
    component: lazy(() => import('./UserDetails'))
  },
  {
    path: '/management/users/:id/:tab',
    exact: true,
    component: lazy(() => import('./UserDetails'))
  },
  {
    path: '/management/companyprofile/:tab',
    exact: true,
    component: lazy(() => import('./CompanyProfile'))
  },
  {
    path: '/management/metrics',
    exact: true,
    component: lazy(() => import('./Metrics'))
  }
];
