import {lazy} from 'react';

export const routes = [
  {
    path: '/market/listings',
    exact: true,
    component: lazy(() => import('./ListingsList'))
  },
  {
    path: '/market/listings/:id',
    exact: true,
    component: lazy(() => import('./ListingDetails'))
  },
  {
    path: '/market/listings/:id/:tab',
    exact: true,
    component: lazy(() => import('./ListingDetails'))
  },
  {
    path: '/market/rfqs',
    exact: true,
    component: lazy(() => import('./RfqsList'))
  },
  {
    path: '/market/rfqs/:id',
    exact: true,
    component: lazy(() => import('./RfqDetails'))
  },
  {
    path: '/market/rfqs/:id/:tab',
    exact: true,
    component: lazy(() => import('./RfqDetails'))
  },
  {
    path: '/listings',
    exact: true,
    component: lazy(() => import('./ListingsManagementList'))
  },
  {
    path: '/listings/:id',
    exact: true,
    component: lazy(() => import('./ListingDetails'))
  },
  {
    path: '/listings/:id/:tab',
    exact: true,
    component: lazy(() => import('./ListingDetails'))
  },
  {
    path: '/rfqs',
    exact: true,
    component: lazy(() => import('./RfqsManagementList'))
  },
  {
    path: '/rfqs/:id',
    exact: true,
    component: lazy(() => import('./RfqDetails'))
  },
  {
    path: '/rfqs/:id/:tab',
    exact: true,
    component: lazy(() => import('./RfqDetails'))
  },
  {
    path: '/orders',
    exact: true,
    component: lazy(() => import('./OrdersManagementList'))
  },
  {
    path: '/orders/:id',
    exact: true,
    component: lazy(() => import('./OrderDetails'))
  },
  {
    path: '/orders/:id/:tab',
    exact: true,
    component: lazy(() => import('./OrderDetails'))
  },
  {
    path: '/dashboard',
    exact: true,
    component: lazy(() => import('./Dashboard'))
  },
  {
    path: '/dashboard/:tab',
    exact: true,
    component: lazy(() => import('./Dashboard'))
  },
  {
    path: '/threads',
    exact: true,
    component: lazy(() => import('./ThreadsManagementList'))
  },
  {
    path: '/threads/:id',
    exact: true,
    component: lazy(() => import('./ThreadDetails'))
  },
  {
    path: '/masterdata/product-category',
    exact: true,
    component: lazy(() => import('./MasterData/ProductCategory'))
  },
  {
    path: '/masterdata/currency',
    exact: true,
    component: lazy(() => import('./MasterData/Currency'))
  },
  {
    path: '/masterdata/product',
    exact: true,
    component: lazy(() => import('./MasterData/Product'))
  }
];

export const listingsListPathFunc = item => "/market/listings";
export const listingsDetailsPathFunc = item => `/market/listings/${item.id}/summary`;
export const listingsDetailsPathFuncThr = item => `/market/listings/${item.id}/thr`;
export const listingsManagementListPathFunc = item => "/listings";
export const listingsManagementDetailsPathFunc = item => item.threadId ? `/threads/${item.threadId}` : `/listings/${item.id}/summary`;
export const rfqsListPathFunc = item => "/market/rfqs";
export const rfqsDetailsPathFunc = item => `/market/rfqs/${item.id}/summary`;
export const rfqsDetailsPathFuncThr = item => `/market/rfqs/${item.id}/thr`;
export const rfqsManagementListPathFunc = item => "/rfqs";
export const rfqsManagementDetailsPathFunc = item => item.threadId ? `/threads/${item.threadId}` : `/rfqs/${item.id}/summary`;
export const quotationsListPathFunc = item => "/quotations";
export const quotationsDetailsPathFunc = item => `/quotations/${item.id}/moreinfo`;
export const ordersListPathFunc = item => "/orders";
export const ordersDetailsPathFunc = item => `/orders/${item.threadId}/overview`;
export const threadsListPathFunc = item => "/threads";
export const threadsDetailsPathFunc = item => `/threads/${item.id}`;

export const detailsPathFunc = item => {
    if (item.type === 'LIS') {
        return listingsDetailsPathFunc;
    } else if (item.type === 'RFQ') {
        return rfqsDetailsPathFunc;
    } else if (item.type === 'QUO') {
        return quotationsDetailsPathFunc;
    } else if (item.type === 'ORD') {
        return ordersDetailsPathFunc;
    }
}
