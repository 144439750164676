import palette from '../palette'

export default {
  root: {
    backgroundColor: palette.primary[200],
    color: palette.primary[500],
  },
  deletable: {
    '&:focus': {
      backgroundColor: palette.primary[100],
    },
  },
}
