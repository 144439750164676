/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  Divider,
  List,
  Typography,
  Hidden,
  IconButton,
  Badge,
  Link,
  colors
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';

import Label from "components/Label";
import NavItem from 'components/NavItem';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(2, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(8),
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200],
  },
}))

function renderNavItems({
                          // eslint-disable-next-line react/prop-types
                          items, subheader, key, ...rest
                        }, stats) {
  return (
    <List key={key}>
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }, stats),
        []
      )}
    </List>
  );
}

function getLabel(label, href, stats) {
  if (label) {
    return label;
  }

  if (href === '/market/buyorders' && stats && stats.stats && stats.stats['activeBuyOrders']) {
    return () => (
        <Label
            color={colors.red[500]}
            shape="rounded"
        >
          {stats.stats['activeBuyOrders']}
        </Label>
    );
  }

  if (href === '/market/sellorders' && stats && stats.stats && stats.stats['activeSellOrders']) {
    return () => (
        <Label
            color={colors.red[500]}
            shape="rounded"
        >
          {stats.stats['activeSellOrders']}
        </Label>
    );
  }

  return null;
}

function reduceChildRoutes({
                             acc, pathname, item, depth = 0
                           }, stats) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    const nItem = <NavItem
      depth={depth}
      icon={item.icon}
      key={item.href + (item.legacy ? item.legacy : '')}
      label={item.label}
      open={Boolean(open)}
      title={item.title}
    >
      {renderNavItems({
        depth: depth + 1,
        pathname,
        items: item.items
      }, stats)}
    </NavItem>;

    if (item.hidden) {
      acc.push(<Hidden only={item.hidden} key={item.href + (item.legacy ? item.legacy : '')}>{nItem}</Hidden>);
    } else {
      acc.push(nItem);
    }
  } else {
    const nItem = <NavItem
      depth={depth}
      href={item.href}
      icon={item.icon}
      key={item.href + (item.legacy ? item.legacy : '')}
      legacy={item.legacy}
      label={getLabel(item.label, item.href, stats)}
      title={item.title}
    />;

    if (item.hidden) {
      acc.push(<Hidden only={item.hidden} key={item.href + (item.legacy ? item.legacy : '')}>{nItem}</Hidden>);
    } else {
      acc.push(nItem);
    }
  }

  return acc;
}

function NavBar({
                  openMobile,
                  onMobileClose,
                  className,
                  stats,
                  navigationConfig,
                  email,
                  firstName,
                  lastName,
                  ...rest
                }) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {navigationConfig && (
        <nav className={classes.navigation}>
          {navigationConfig
            .map((list) => renderNavItems({
              items: list.items,
              subheader: list.subheader,
              pathname: location.pathname,
              key: list.subheader
            }, stats))
          }
        </nav>
      )}
      <Divider className={classes.divider}/>
      <div className={classes.profile}>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          classes={{
            dot: classes.badgeDot,
            badge: clsx({
              [classes.badge]: true,
              [classes.onlineBadge]: true
            })
          }}
          variant="dot"
        >
        </Badge>
        <div className={classes.details}>
          <Link
            component={RouterLink}
            to="/settings/user/general"
            variant="h5"
            color="textPrimary"
            underline="none"
          >
            {`${firstName} ${lastName}`}
          </Link>
          <Typography variant="body2">{email}</Typography>
        </div>
        <IconButton
          className={classes.moreButton}
          size="small"
        >
          <MoreIcon/>
        </IconButton>
      </div>
    </div>
  );

  return (
    <>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  return {
    navigationConfig: state.navigation.config,
    email: state.auth.user.email,
    firstName: state.auth.user.firstName,
    lastName: state.auth.user.lastName,
    stats: state.dashboard
  };
};

export default connect(
  mapStateToProps
)(NavBar);

