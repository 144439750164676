import palette from '../palette'

export default {
  contained: {
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14)',
    backgroundColor: palette.primary[100],
    '&:hover': {
      backgroundColor: palette.primary[300],
    },
  },
}
