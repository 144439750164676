import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  Popover,
  CardHeader,
  CardActions,
  Divider,
  Button,
  colors
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import * as navigationActions from "actions/navigationActions";
import * as notificationActions from "modules/notifications/actions";

import NotificationList from './NotificationList';

const useStyles = makeStyles(() => ({
  root: {
    width: 350,
    maxWidth: '100%'
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center'
  }
}));

function NotificationsPopover({ anchorEl, numUnread, notifications, onClose, actions, ...rest }) {
  const classes = useStyles();

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <div className={classes.root}>
        <CardHeader title="Notifications"/>
        <Divider/>
        {notifications.length > 0 ? (
          <NotificationList numUnread={numUnread} notifications={notifications} onClose={onClose} actions={actions}/>
        ) : null}
        <Divider/>
        <CardActions className={classes.actions}>
          <Button
            component={RouterLink}
            size="small"
            onClick={() => {
              onClose();
            }}
            to="/notifications"
          >
            See all
          </Button>
          <Button
            size="small"
            onClick={() => {
              onClose();
              actions.markAllAsRead();
            }}
          >
            Mark all as read
          </Button>
        </CardActions>
      </div>
    </Popover>
  );
}

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  numUnread: PropTypes.number,
  notifications: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...navigationActions, ...notificationActions }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsPopover);
