import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';

import {setChatOpen} from "../../../services/config/user.config.service";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import gradients from 'utils/gradients';

const useStyles = makeStyles((theme) => ({
  root: {},
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  unread: {
    backgroundColor: 'rgba(24,85,132,0.08)'
  },
  avatarBlue: {
    backgroundImage: gradients.blue
  },
  avatarGreen: {
    backgroundImage: gradients.green
  },
  avatarOrange: {
    backgroundImage: gradients.orange
  },
  avatarIndigo: {
    backgroundImage: gradients.indigo
  },
  arrowForwardIcon: {
    color: theme.palette.icon
  }
}));

function NotificationList({ className, numUnread, notifications, onClose, actions, ...rest }) {
  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)} disablePadding>
      {notifications &&
        notifications.slice(0, 8).map((notif, i) => (
          <ListItem
            className={clsx(classes.listItem, {
              [classes.unread]: !notif.read
            })}
            component={RouterLink}
            divider={i < notifications.length - 1}
            key={notif.id}
            onClick={() => {
              onClose();
              notif.subject === 'Chat Message Received' && setChatOpen();
              actions.markAsRead(notif.id);
            }}
            to={notif.url ? notif.url : '#'}
          >
            <ListItemText
              primary={notif.subject}
              primaryTypographyProps={{ variant: 'body1' }}
              secondary={
                <>
                  {notif.message}
                  {/* <br />
                  {moment(notif.metaData.createdAt).fromNow()} */}
                </>
              }
            />
            <ArrowForwardIcon className={classes.arrowForwardIcon} />
          </ListItem>
        ))}
    </List>
  );
}

NotificationList.propTypes = {
  className: PropTypes.string,
  numUnread: PropTypes.number,
  notifications: PropTypes.array
};

export default NotificationList;
