import * as actionTypes from "./actionTypes";
import jwt_decode from "jwt-decode";
import { history } from "store/configureStore";
import * as authService from "services/auth/auth.service";
import * as navigationActions from "./navigationActions";
import * as socketActions from "./socketActions";
import * as helperActions from "./helperActions";
import * as settingsActions from "./settingsActions";
import * as listingsActions from "modules/listings/actions";
import * as notificationActions from "modules/notifications/actions";
import * as userConfigSvc from "../services/config/user.config.service";

export const authInit = () => dispatch => {
  window.location.href = process.env.REACT_APP_LOGON_APP_URL;
};

export const login = res => (dispatch) => {
  authService.setToken(res.accessToken);
  dispatch({
    type: actionTypes.AUTH_LOGGED_IN,
    payload: res
  });

  socketActions.setupSockets(res.accessToken, res.user.companyId);
  dispatch(notificationActions.loadLastCheckedNotifications());
  dispatch(notificationActions.loadNotifications());
  dispatch(settingsActions.settings2Load());
  dispatch(navigationActions.createNavigation(res.user));
  dispatch(listingsActions.loadUserProfile());
};

export const storeAndCheckToken = (res) => dispatch => {
  authService.setToken(res.accessToken);
  dispatch(checkToken({logoutIfNoToken: true}));
};

export const logout = () => dispatch => {
  dispatch({type: actionTypes.AUTH_LOGOUT});
  authService.removeTokens();
  dispatch({type: actionTypes.AUTH_LOGGED_OUT});
  authService.clearRedirect();
  window.location.href = process.env.REACT_APP_LOGON_APP_URL;
};

export const logoutSetRedirect = () => dispatch => {
  // dispatch({type: actionTypes.AUTH_LOGOUT});
  // authService.removeTokens();
  // dispatch({type: actionTypes.AUTH_LOGGED_OUT});
  // authService.setRedirect(window.location.href);
  // window.location.href = process.env.REACT_APP_LOGON_APP_URL;
};

export const checkToken =
  ({ logoutIfNoToken, refresh }) =>
  dispatch => {
    dispatch({ type: actionTypes.AUTH_VALIDATE });
    const accessToken = authService.getToken();

    if (!accessToken && logoutIfNoToken) {
      dispatch(logoutSetRedirect());
    } else if (accessToken) {
      authService
        .checkStatus(accessToken, refresh)
        .then(res => {
          dispatch(login(res.data));
        })
        .catch(err => {
          //if token is not valid/too old - show login form
          dispatch(logoutSetRedirect());
        });
    }
  };

export const checkTokenLocally = () => dispatch => {
  const accessToken = authService.getToken();
  if (!accessToken) {
    dispatch(logoutSetRedirect());
  } else {
    try {
      const decoded = jwt_decode(accessToken);
      if (decoded.exp < Date.now() / 1000) {
        const onBehalfToken = authService.getOnBehalfToken();
        if (
          onBehalfToken &&
          jwt_decode(onBehalfToken).exp > Date.now() / 1000
        ) {
          dispatch(exitOnBehalf());
        } else {
          dispatch(logoutSetRedirect());
        }
      }
    } catch (err) {
      dispatch(logoutSetRedirect());
    }
  }
};

export const refreshToken = () => dispatch => {
  const accessToken = authService.getToken();
  if (!accessToken) {
    dispatch(logoutSetRedirect());
  } else {
    authService
      .checkStatus(accessToken, true)
      .then(res => {
        authService.setToken(res.data.accessToken);
        dispatch({
          type: actionTypes.AUTH_LOGGED_IN,
          payload: res.data
        });
      })
      .catch(err => {
        // if token is not valid/too old - show login form
        dispatch(logoutSetRedirect());
      });
  }
};

export const loginOnBehalf = companyId => dispatch => {
  dispatch({ type: actionTypes.AUTH_ON_BEHALF_LOG_IN });
  authService
    .loginOnBehalf(companyId)
    .then(response => {
      authService.onBehalfLogIn(response.data.accessToken);
      checkToken({ logoutIfNoToken: false });
      dispatch({ type: actionTypes.AUTH_ON_BEHALF_LOGGED_IN });
      userConfigSvc.setConfig('onbehalf_location', history.location.pathname);
      history.push(`/`);
      window.location.reload();
    })
    .catch(err => {
      console.log('loginOnBehalf failed', err);
      dispatch({ type: actionTypes.AUTH_ON_BEHALF_LOG_IN_FAILED });
    });
};

export const exitOnBehalf = () => dispatch => {
  dispatch({ type: actionTypes.AUTH_ON_BEHALF_LOGOUT });
  authService.onBehalfLogOut();
  checkToken({ logoutIfNoToken: false });
  dispatch({ type: actionTypes.AUTH_ON_BEHALF_LOGGED_OUT });
  const onbehalfLocation = userConfigSvc.getConfig('onbehalf_location');
  userConfigSvc.setConfig('onbehalf_location');
  history.push(onbehalfLocation ? onbehalfLocation : `/`);
  window.location.reload();
};

export const resetPassword = email => dispatch => {
  authService
    .resetPassword(email)
    .then(res => {
      if (!res.data.success) {
        console.log('resetPassword error', res.data.error);
        dispatch(helperActions.errorMessage('Resetting password failed'));
      } else {
        dispatch(
          helperActions.successMessage(
            'Reset password email was sent to "' + email + '"'
          )
        );
      }
    })
    .catch(err => {
      console.log('resetPassword error', err);
      dispatch(helperActions.errorMessage('Resetting password failed'));
    });
};
