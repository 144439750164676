import { lazy } from 'react';

export const routes = [
  {
    path: '/public/profile/:profileId',
    exact: true,
    component: lazy(() => import('./Profile/'))
  },
  {
    path: '/profile/management',
    exact: true,
    component: lazy(() => import('./Profile/management/ProfileManagement'))
  },
];