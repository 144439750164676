import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { authStates } from "store/constants";
import { history } from "../../store/configureStore";
import * as authActions from "actions/authActions";
import * as authService from "services/auth/auth.service";

const NODE_ENV = process.env.NODE_ENV;

class AuthProvider extends React.PureComponent {
  componentDidMount() {
    const { actions } = this.props;

    window.addEventListener("message", this.receiveMessage, false);

    if (!isOpenUrl(history)) {
      if (NODE_ENV === 'development') {
        //actions.checkTokenLocally();
        actions.checkToken({logoutIfNoToken: false});
      } else {
        actions.checkToken({logoutIfNoToken: true});
      }
    }
  }

  receiveMessage = event => {
    const { actions } = this.props;
    if (event.data && event.data.token) {
      if (NODE_ENV === 'development') {
        // localhost log in
        actions.storeAndCheckToken({ accessToken: event.data.token });
      }
    }
  };

  render() {
    const { status, appContent } = this.props;
    if ((status === authStates.AUTH_STATE_LOGGED_IN && authService.isLoggedOn()) || isOpenUrl(history)) {
      return appContent;
    }

    return <div/>
  }
}

const isOpenUrl = (history) => {
  return history.location.pathname.startsWith('/o/');
};

const mapStateToProps = (state, ownProps) => {
  return {
    status: state.auth.status
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...authActions }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthProvider);
