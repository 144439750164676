import * as actionTypes from "actions/actionTypes";
import {authStates} from "store/constants";
import * as managementActions from "modules/management/actions/actionTypes";

const initialState = {
  token: null,
  user: null,
  boUserId: null,

  form: null,
  formErrors: {},

  status: authStates.AUTH_STATE_UNKNOWN,
  meta: null,
  backendError: null,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_VALIDATE:
      return {
        ...state,
        status: authStates.AUTH_STATE_LOADING,
        meta: ""
      };

    case actionTypes.AUTH_LOGGED_IN:
      return {
        ...state,
        backendError: null,
        token: action.payload.accessToken,
        user: {
          ...action.payload.user,
          permissionMap: action.payload.user.permissionsMap
        },
        boUserId: action.payload.boId,
        status: authStates.AUTH_STATE_LOGGED_IN
      };

    case actionTypes.AUTH_LOGOUT:
      return {
        ...state,
        backendError: null
      };

    case actionTypes.AUTH_LOGGED_OUT:
      return {
        ...state,
        token: null,
        user: null,
        boUserId: null,
        form: null,
        formErrors: null,
        status: authStates.AUTH_STATE_LOGGED_OUT,
        meta: null,
        backendError: null
      };

    case actionTypes.AUTH_ON_BEHALF_LOG_IN:
      return {
        state
      };

    case actionTypes.AUTH_ON_BEHALF_LOGGED_IN:
      return {
        state
      };

    case actionTypes.AUTH_ON_BEHALF_LOG_IN_FAILED:
      return {
        state
      };

    case actionTypes.AUTH_ON_BEHALF_LOGOUT:
      return {
        state
      };

    case actionTypes.AUTH_ON_BEHALF_LOGGED_OUT:
      return {
        state
      };

    case managementActions.AUTH_UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          phone: action.payload.phone
        }
      };

    default:
      return state;
  }
};
