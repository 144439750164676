//@ts-nocheck
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {routerMiddleware, connectRouter} from "connected-react-router";
import {createHashHistory} from "history";
import thunk from "redux-thunk";

import appReducers from "reducers";
import * as actionTypes from "actions/actionTypes";

export const history = createHashHistory({
  hashType: "slash",
  getUserConfirmation: (message, callback) => callback(window.confirm(message))
});

export const configureStore = (preloadedState = {}) => {
  if (
    process.env.REACT_APP_ENV === "production" &&
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__ &&
    typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object"
  ) {
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () {
    };
  }

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const historyMiddleware = routerMiddleware(history);

  const combinedReducers = combineReducers({
    ...appReducers,
    router: connectRouter(history)
  });

  // this erases all state on logout, so another user can't see previous users data
  const rootReducer = (state, action) => {
    if (action.type === actionTypes.AUTH_LOGGED_OUT) {
      state = undefined;
    }
    return combinedReducers(state, action);
  };

  let store = createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(historyMiddleware, thunk)));

  return store;
};
