export const LISTING_FILTER = "LISTING_FILTER";
export const SET_FILTER = "SET_FILTER";
export const GENERATE_FILTER_OBJECT = "GENERATE_FILTER_OBJECT";
export const RESET_FILTER = "RESET_FILTER";
export const SET_FAVOURITES = "SET_FAVOURITES";
export const WITH_SELECTED_FILTER = "WITH_SELECTED_FILTER";
export const SET_ATTRIBUTE = "SET_ATTRIBUTE";

export const setFilter = (section, id) => dispatch => {
  dispatch({type: LISTING_FILTER + SET_FILTER, payload: {id: id, section: section}});
};

export const resetFilters = () => (dispatch, getState) => {
  dispatch({type: LISTING_FILTER + RESET_FILTER,
    payload: {
      selectedHierarchy: getState().hierarchy.id,
      productTypes: getState().settings.productTypes,
      productTypesHierarchyMap: getState().settings.productTypesHierarchyMap,
    },
  });
}

export const updateFilters = () => (dispatch, getState) => {
  const filter = getState().userProfile.filters[getState().userProfile.selectedFilter];
  dispatch({type: LISTING_FILTER + GENERATE_FILTER_OBJECT + WITH_SELECTED_FILTER,
    payload: {
      args: {
        productTypes: getState().settings.productTypes,
        selectedHierarchy: filter.productHierarchy.id,
        productTypesHierarchyMap: getState().settings.productTypesHierarchyMap,
      },
      selectedFilter: filter,
    }
  });
}

export const setFilterAttribute = (attributeId, value) => dispatch => {
  dispatch({
    type: LISTING_FILTER + SET_ATTRIBUTE,
    payload: {
      attributeId : attributeId,
      value : value,
    }
  });
}

export const setFavourites = (favourites) => dispatch => {
  dispatch({type: LISTING_FILTER + SET_FAVOURITES,
    payload: {
      favourites: favourites,
    },
  });
}