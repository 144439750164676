import { dispatch } from "../App";
import io from "socket.io-client";
import { PushMessageType } from '@jetseafoodas/sfp-types';
import * as helperActions from "./helperActions";
import * as authActions from "./authActions";
import * as notificationActions from "modules/notifications/actions";
import { Emitter } from '../services/emitter';
import { getRequestCode } from '../services/auth/auth.service';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Socket setup
export const setupSockets = (accessToken, companyId) => {
  //console.log('setupSockets', accessToken, companyId);

  let socketGlobal = io(BACKEND_URL + '/', {
    query: {
      token: accessToken
    }
  });

  let socketCompany = companyId ? io(BACKEND_URL + `/company/${companyId}`, {
    query: {
      token: accessToken
    }
  }) : undefined;

  let socketAdmin = !companyId ? io(BACKEND_URL + `/admin`, {
    query: {
      token: accessToken
    }
  }) : undefined;

  socketGlobal.on(PushMessageType.PUSH_MSG_LISTING_UPDATED, handleListingUpdated);
  socketGlobal.on(PushMessageType.PUSH_MSG_RFQ_UPDATED, handleRfqUpdated);
  socketGlobal.on(PushMessageType.ORDER_DOCUMENT_UPLOADED, handleDocumentUploaded);
  socketGlobal.on(PushMessageType.PUSH_MSG_SERVER_MESSAGE, handleServerMessage);

  if (socketCompany) {
    socketCompany.on(PushMessageType.PUSH_MSG_TOKEN_REFRESH, handleTokenRefresh);
    socketCompany.on(PushMessageType.PUSH_MSG_NOTIFICATION, handleNotification);
    socketCompany.on(PushMessageType.PUSH_MSG_SERVER_MESSAGE, handleServerMessage);
    socketCompany.on(PushMessageType.PUSH_MSG_LISTING_UPDATED, handleListingUpdated);
    socketCompany.on(PushMessageType.PUSH_MSG_RFQ_UPDATED, handleRfqUpdated);
    socketCompany.on(PushMessageType.PUSH_MSG_THREAD_UPDATED, handleThreadUpdated);
    socketCompany.on(PushMessageType.PUSH_MSG_CHAT_UPDATED, handleChatUpdated);
    socketCompany.on(PushMessageType.PUSH_MSG_CHANGE_UPDATED, handleChangeUpdated);
  }

  if (socketAdmin) {
    socketAdmin.on(PushMessageType.PUSH_MSG_NOTIFICATION, handleNotification);
  }
};

// Company handlers

const handleTokenRefresh = (message) => {
  logMsg(`handleTokenRefresh: refresh token`);
  if (message.requestCode !== getRequestCode()) {
    dispatch(authActions.refreshToken());
  }
};

const handleNotification = () => {
  logMsg(`handleNotification: reload`);
  dispatch(notificationActions.loadNotifications());
};

const handleListingUpdated = (message) => {
    //console.log("LISTING UPDATED:", message);
    if(message.requestCode !== getRequestCode() ){
        Emitter.emit(PushMessageType.PUSH_MSG_LISTING_UPDATED, message);
    }
}

const handleRfqUpdated = (message) => {
  //console.log("RFQ UPDATED:", message);
  if(message.requestCode !== getRequestCode() ){
    Emitter.emit(PushMessageType.PUSH_MSG_RFQ_UPDATED, message);
  }
}

const handleThreadUpdated = (message) => {
  //console.log("THREAD UPDATED:", message);
  if(message.requestCode !== getRequestCode() ){
    Emitter.emit(PushMessageType.PUSH_MSG_THREAD_UPDATED, message);
  }
}

const handleChatUpdated = (message) => {
    //console.log("CHAT UPDATED:", message);
    if(message.requestCode !== getRequestCode() ){
        Emitter.emit(PushMessageType.PUSH_MSG_CHAT_UPDATED, message);
    }
}

const handleChangeUpdated = (message) => {
  //console.log("CHANGE UPDATED:", message);
  if(message.requestCode !== getRequestCode() ){
    Emitter.emit(PushMessageType.PUSH_MSG_CHANGE_UPDATED, message);
  }
}

const handleDocumentUploaded = (message) => {
    //console.log("DOCUMENT UPLOADED:", message);
    if(message.requestCode !== getRequestCode() ){
        Emitter.emit(PushMessageType.ORDER_DOCUMENT_UPLOADED, message);
    }
}

const handleServerMessage = (message) => {
  console.log('Server message:', message);
  if (message.requestCode === getRequestCode()) { // Only show own server messages
    dispatch(helperActions.serverErrorMessage(messageToStr(message)));
  }
};

const messageToStr = (message) => {
  let msg = message.message;
  if (message.error) {
    if (message.error.message) msg += '\n' + message.error.message;
    if (message.error.errors) msg += '\n' + message.error.errors;
    if (message.error.details) msg += '\n' + message.error.details;
    if (message.error.stack) msg += '\n' + message.error.stack;
  }
  return msg;
}

const logMsg = (msg, ...params) => {
  //console.log('socket message', msg, params);
};

export default setupSockets;
