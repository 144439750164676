import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from "clsx";
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {makeStyles} from '@material-ui/styles';
import {
  Hidden,
  colors,
  List,
  ListItem,
  ListItemText as MuiListItemText,
  Popover,
  withStyles,
} from '@material-ui/core'

import * as authActions from 'actions/authActions'
import * as navigationActions from 'actions/navigationActions'
import InputIcon from '@material-ui/icons/Input'
import { perm as permissions } from '@jetseafoodas/sfp-types'
import { theme } from 'themes/defaulttheme'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    maxWidth: '100%',
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center',
  },
  exitOnBehalfButton: {
    backgroundColor: colors.orange[400],
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  listItemText: {
    color: theme.palette.primary.main,
  },
  listItemOnBehalf: {
    backgroundColor: colors.orange[400],
    '&:hover': {
      backgroundColor: colors.orange[600],
    },
  },
  arrowForwardIcon: {
    color: theme.palette.icon,
  },
}))

const ListItemText = withStyles({
  primary: {
    color: theme.palette.primary.main,
  },
})(MuiListItemText)

const UserInfoPopover = (props) => {
  const {
    firstName,
    lastName,
    email,
    permissionMap,
    companyName,
    VATNumber,
    registrationNumber,
    boUserId,
    actions,
    anchor,
    onClose,
    ...rest
  } = props

  const classes = useStyles()

  return (
    <Popover
      {...rest}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <div className={classes.root}>
        <List {...rest} className={clsx(classes.root)} disablePadding>
          {companyName !== 'JET' &&
            permissionMap &&
            (permissions.SEC_MANAGE_COMPANY_SETTINGS in permissionMap || permissions.SEC_PUBLIC_LISTING in permissionMap) && (
              <Hidden smDown>
                {permissions.SEC_MANAGE_COMPANY_SETTINGS in permissionMap && <ListItem
                  component={RouterLink}
                  className={classes.listItem}
                  to="/listings"
                >
                  <ListItemText
                    primary="My Listings"
                    primaryTypographyProps={{ variant: 'body1' }}
                  />
                </ListItem>}
                <ListItem
                  component={RouterLink}
                  className={classes.listItem}
                  to="/orders"
                >
                  <ListItemText
                    primary="Order List"
                    primaryTypographyProps={{ variant: 'body1' }}
                  />
                </ListItem>
                <ListItem
                  className={classes.listItem}
                  key={'company-settings'}
                  button
                  component={RouterLink}
                  onClick={e => {
                    onClose();
                  }}
                  to="/profile/management"
                >
                  <ListItemText
                    primary={'Company Details'}
                    primaryTypographyProps={{ variant: 'body1' }}
                  />
                </ListItem>
                {/* <ListItem
                  className={classes.listItem}
                  key={'company-profile'}
                  dense
                  button
                  component={RouterLink}
                  onClick={(e) => {
                    onClose()
                  }}
                  to="/profile/management"
                >
                  <ListItemText
                    primary={'Company Profile'}
                    primaryTypographyProps={{ variant: 'body1' }}
                  />
                </ListItem> */}
              </Hidden>
            )}
          <ListItem
            className={classes.listItem}
            key={'user'}
            button
            component={RouterLink}
            onClick={() => {
              onClose();
            }}
            to="/settings/user/general"
          >
            {/* <ListItemText
              primary={firstName + ' ' + lastName}
              primaryTypographyProps={{ variant: 'body1' }}
              secondary={email}
            /> */}
            <ListItemText
              primary={'Account Settings'}
              primaryTypographyProps={{ variant: 'body1' }}
              // secondary={email}
            />
          </ListItem>
          {boUserId && (
            <ListItem
              className={classes.listItemOnBehalf}
              key={'exitOnBehalf'}
              dense
              button
              onClick={() => actions.exitOnBehalf()}
            >
              <ListItemText
                primary={'Exit on behalf'}
                primaryTypographyProps={{ variant: 'body1' }}
              />
              <InputIcon color="primary" className={classes.logoutIcon} />
            </ListItem>
          )}
          {/* <ListItem
            className={classes.listItem}
            key={'contacts'}
            dense
            button
            component={RouterLink}
            onClick={(e) => {
              onClose()
            }}
            to="/settings/contacts"
          >
            <ListItemText
              primary={'Contacts'}
              primaryTypographyProps={{ variant: 'body1' }}
            />
          </ListItem>
          <ListItem
            className={classes.listItem}
            key={'currency'}
            dense
            button
            component={RouterLink}
            onClick={(e) => {
              onClose()
            }}
            to="/settings/currency"
          >
            <ListItemText
              primary={'Currency'}
              primaryTypographyProps={{ variant: 'body1' }}
            />
          </ListItem> */}
          <ListItem
            className={classes.listItem}
            key={'signOut'}
            button
            onClick={() => actions.logout()}
          >
            <ListItemText
              primary={'Log out'}
              primaryTypographyProps={{ variant: 'body1' }}
            />
            {/* <InputIcon color="primary" className={classes.logoutIcon} /> */}
          </ListItem>
        </List>
      </div>
    </Popover>
  );
}

UserInfoPopover.propTypes = {
  anchor: PropTypes.any,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    status: state.auth.status,
    firstName: state.auth.user.firstName,
    lastName: state.auth.user.lastName,
    email: state.auth.user.email,
    permissionMap: state.auth.user.permissionMap,
    companyName: state.auth.user.companyName,
    VATNumber: state.auth.user.VATNumber,
    registrationNumber: state.auth.user.registrationNumber,
    boUserId: state.auth.boUserId
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({...authActions, ...navigationActions}, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInfoPopover);

