import React, { useContext } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { ConnectedRouter } from "connected-react-router";

import { ThemeProvider } from '@material-ui/styles';
import { ThemeProvider as ThemeProviderMui } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import AdapterMoment from '@mui/lab/AdapterMoment';

import { SnackbarProvider } from 'notistack';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import { theme, themeMui } from './themes/defaulttheme';
import { configureStore, history } from './store';

import ErrorBoundary from 'components/ErrorBoundary';
import ScrollReset from 'components/ScrollReset';

import './index.css';
import './assets/css/mapbox-gl.css';
import AuthProvider from 'components/AuthProvider';
import Notifier from 'components/Notifier';
import AppContent from './AppContent';
import { newVersionChecker } from './actions/appActions';

import { LocaleContext, LocaleContextProvider } from './LocaleContext';

const store = configureStore();
export const dispatch = store.dispatch;
export const getState = store.getState;

//see: https://redux.js.org/usage/usage-with-typescript
//To do: export typed RootState and Dispatch from more appropriate place
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const GTM_ID = process.env.REACT_APP_GTM_ID;

const App = () => {
  if (GA_MEASUREMENT_ID) {
    ReactGA.initialize(GA_MEASUREMENT_ID, {
      debug: false
    });
  }

  if (GTM_ID) {
    const tagManagerArgs = {
      gtmId: GTM_ID
    };

    TagManager.initialize(tagManagerArgs);
  }

  const localeContext = useContext(LocaleContext);

  //console.log('app', window.location);

  newVersionChecker();

  return (
    // <ErrorBoundary>
    <LocaleContextProvider>
      <StoreProvider store={store}>
        <ThemeProviderMui theme={themeMui}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                // locale={localeContext.locale}
              >
                <ConnectedRouter history={history}>
                  <ScrollReset />
                  <AuthProvider appContent={<AppContent />} />
                  <Notifier />
                </ConnectedRouter>
              </LocalizationProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </ThemeProviderMui>
      </StoreProvider>
    </LocaleContextProvider>
    // </ErrorBoundary>
  );
};

export default App;
