export const authStates = {
  AUTH_STATE_UNKNOWN: "AUTH_STATE_UNKNOWN",
  AUTH_STATE_LOGGED_IN: "AUTH_STATE_LOGGED_IN",
  AUTH_STATE_LOGGED_OUT: "AUTH_STATE_LOGGED_OUT",
  AUTH_STATE_LOGIN_FAILED: "AUTH_STATE_LOGIN_FAILED",
  AUTH_STATE_LOADING: "AUTH_STATE_LOADING"
};

export const statuses = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  LOADED: "LOADED",
  READY: "READY",
  FAILED: "FAILED",
  SAVING: "SAVING",
  DELETING: "DELETING"
};

export const orderStatusesMap = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  WITHDRAWN: 'WITHDRAWN',
  CANCELED: 'CANCELLED',
  REJECTED: 'REJECTED',
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  DELIVERED: 'DELIVERED',
  COMPLETED: 'COMPLETED'
};

export const threadStatusesMap = {
  ACTIVE: 'ACTIVE',
  FULFILLED: 'FULFILLED',
  CANCELLED: 'CANCELLED',
};

export const companyStatuses = ['Idle', 'Approved', 'Rejected', 'Suspended'];
export const filterRoles = [
  'SYSTEM_ADMIN',
  'COMPANY_SUPERADMIN2',
  'COMPANY_ADMIN2',
  'COMPANY_USER2',
];

export const sortMap = {
  'Most recent': {sortField: 'updatedAt', sortOrder: 'desc'},
  'Popular': {sortField: 'numLikes', sortOrder: 'desc'},
  'Volume high': {sortField: 'volume', sortOrder: 'desc'},
  'Volume low': {sortField: 'volume', sortOrder: 'asc'},
  'Avg. size high': {sortField: 'averageSize', sortOrder: 'desc'},
  'Avg. size low': {sortField: 'averageSize', sortOrder: 'asc'},
  'Avg. price high': {sortField: 'averagePrice', sortOrder: 'desc'},
  'Avg. price low': {sortField: 'averagePrice', sortOrder: 'asc'},
  'Totalprice high': {sortField: 'totalPrice', sortOrder: 'desc'},
  'Totalprice low': {sortField: 'totalPrice', sortOrder: 'asc'},
};
