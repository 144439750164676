import React, { useState, useRef, useReducer } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Badge,
  Button,
  ClickAwayListener,
  Menu,
  MenuItem,
  Toolbar,
  colors,
  Divider,
  Typography
} from '@material-ui/core';
import { CaretDown } from '@phosphor-icons/react';

import { IconButton, Box } from '@mui/material';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BarChartIcon from '@material-ui/icons/BarChart';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import {
  ShoppingCart as ShoppingCartIcon,
  Bell as BellIcon,
  UserCircle as UserCircleIcon
} from '@phosphor-icons/react';

import brandIcon from 'assets/img/logo-efishery.png';
import brandIcon2 from 'assets/img/logo-efishery.png';
import * as notificationActions from 'modules/notifications/actions';

import NotificationsPopover from './NotificationsPopover';
import UserInfoPopover from './UserInfoPopover';
import { perm as permissions } from '@jetseafoodas/sfp-types';
import { featureToggle } from '../../featureToggles';
import { theme } from 'themes/defaulttheme';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main
  },
  flexGrow: {
    flexGrow: 1
  },
  topBar: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.primary.main
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  chatButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: '#D97706',
    color: theme.palette.white
  },
  appReloadButton: {
    textTransform: 'none',
    backgroundColor: colors.orange[400],
    marginLeft: theme.spacing(1)
  },
  logo: {
    paddingRight: theme.spacing(6),
    cursor: 'pointer'
  },
  popupMenuFirst: {
    paddingLeft: theme.spacing(6),
    backgroundColor: colors.white
  },
  popupMenu: {
    //paddingLeft: theme.spacing(1),
    backgroundColor: colors.white,
    display: 'flex'
  },
  popupMenuButton: {
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
    textTransform: 'none'
  },
  popupMenuButtonListings: {
    textTransform: 'none'
  },
  popupMenuButtonRfqs: {
    textTransform: 'none'
  },
  exitOnBehalfButton: {
    textTransform: 'none',
    backgroundColor: colors.orange[400],
    marginLeft: theme.spacing(1)
  },
  logoutButton: {
    textTransform: 'none',
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  popover: {
    pointerEvents: 'none'
  },
  popoverContent: {
    pointerEvents: 'auto'
  }
}));

const initialMenuState = {
  dashboardOpen: false,
  settingsOpen: false,
  myOrdersOpen: false,
  managementOpen: false,
  marketManagementOpen: false
};

const menuReducer = (state, action) => {
  switch (action) {
    case 'OPEN_DASHBOARD':
      return {
        ...state,
        dashboardOpen: true
      };
    case 'CLOSE_DASHBOARD':
      return {
        ...state,
        dashboardOpen: false
      };
    case 'OPEN_SETTINGS':
      return {
        ...state,
        settingsOpen: true
      };
    case 'CLOSE_SETTINGS':
      return {
        ...state,
        settingsOpen: false
      };
    case 'OPEN_MY_ORDERS':
      return {
        ...state,
        myOrdersOpen: true
      };
    case 'CLOSE_MY_ORDERS':
      return {
        ...state,
        myOrdersOpen: false
      };
    case 'OPEN_MANAGEMENT':
      return {
        ...state,
        managementOpen: true
      };
    case 'CLOSE_MANAGEMENT':
      return {
        ...state,
        managementOpen: false
      };
    case 'OPEN_MARKET_MANAGEMENT':
      return {
        ...state,
        marketManagementOpen: true
      };
    case 'CLOSE_MARKET_MANAGEMENT':
      return {
        ...state,
        marketManagementOpen: false
      };
    default:
      throw Error('Unknown menu item');
  }
};

function TopBarGuest({
  permissionMap,
  onOpenNavBarMobile,
  className,
  boUserId,
  companyName,
  firstName,
  lastName,
  numTotal,
  numUnread,
  notifications,
  actions,
  ...rest
}) {
  const classes = useStyles();
  const notificationsRef = useRef(null);
  const userInfoRef1 = useRef(null);
  const userInfoRef2 = useRef(null);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openUserInfo, setOpenUserInfo] = useState(false);

  const matchesLG = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const dashboardAnchor = useRef(null);
  const orderAnchor = useRef(null);
  const settingsAnchor = useRef(null);
  const managementAnchor = useRef(null);
  const marketManagementAnchor = useRef(null);

  const [menuState, dispatch] = useReducer(menuReducer, initialMenuState);
  const openDashboard = () => dispatch('OPEN_DASHBOARD');
  const closeDashboard = () => dispatch('CLOSE_DASHBOARD');
  const openOrderMenu = () => dispatch('OPEN_MY_ORDERS');
  const closeOrderMenu = () => dispatch('CLOSE_MY_ORDERS');
  const openSettingsMenu = () => dispatch('OPEN_SETTINGS');
  const closeSettingsMenu = () => dispatch('CLOSE_SETTINGS');
  const openManagementMenu = () => dispatch('OPEN_MANAGEMENT');
  const closeManagementMenu = () => dispatch('CLOSE_MANAGEMENT');
  const openMarketManagementMenu = () => dispatch('OPEN_MARKET_MANAGEMENT');
  const closeMarketManagementMenu = () => dispatch('CLOSE_MARKET_MANAGEMENT');

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
    actions.updateLastCheckedNotifications();
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleUserInfoOpen = () => {
    setOpenUserInfo(true);
  };

  const handleUserInfoClose = () => {
    setOpenUserInfo(false);
  };

  const handleClickOnLogo = event => {
    event.preventDefault();
    window.location.href = process.env.REACT_APP_PORTAL_APP;
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color={theme.palette.primary.main}
    >
      {permissionMap[permissions.SEC_MANAGE_COMPANY_SETTINGS] ||
        (permissionMap[permissions.SEC_ALL_DATA_LISTING] && (
          <Toolbar className={classes.topBar} variant="dense">
            <div
              className={classes.popupMenu}
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                ref={marketManagementAnchor}
                className={classes.popupMenuButton}
                color="inherit"
                aria-owns={
                  menuState.marketManagementOpen
                    ? 'market-management-menu'
                    : undefined
                }
                aria-haspopup="true"
                onClick={openMarketManagementMenu}
                onMouseEnter={openMarketManagementMenu}
                onMouseLeave={closeMarketManagementMenu}
              >
                <Typography
                  variant="body1"
                  style={{ color: 'white', marginRight: '8px' }}
                >
                  Market management
                </Typography>
                <CaretDown size={24} />
              </Button>
              <Menu
                id="market-management-menu"
                anchorEl={marketManagementAnchor.current}
                open={menuState.marketManagementOpen}
                onClose={closeMarketManagementMenu}
                MenuListProps={{
                  onMouseLeave: closeOrderMenu,
                  style: {
                    minWidth: marketManagementAnchor?.current?.offsetWidth
                  }
                }}
                PaperProps={{
                  onMouseEnter: openMarketManagementMenu,
                  onMouseLeave: closeMarketManagementMenu
                }}
                PopoverClasses={{
                  root: classes.popover,
                  paper: classes.popoverContent
                }}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transitionDuration={0}
              >
                {permissionMap[permissions.SEC_ALL_DATA_LISTING] && (
                  <MenuItem component={RouterLink} to="/listings">
                    Listings
                  </MenuItem>
                )}
                {permissionMap[permissions.SEC_ALL_DATA_RFQ] && (
                  <MenuItem component={RouterLink} to="/rfqs">
                    RfQs
                  </MenuItem>
                )}
                {permissionMap[permissions.SEC_ALL_DATA_LISTING] && (
                  <MenuItem component={RouterLink} to="/threads">
                    Threads
                  </MenuItem>
                )}
                {(permissionMap[permissions.SEC_ALL_DATA_LISTING] ||
                  permissionMap[permissions.SEC_ALL_DATA_RFQ]) && (
                  <MenuItem component={RouterLink} to="/orders">
                    Orders
                  </MenuItem>
                )}
              </Menu>
              <Button
                ref={managementAnchor}
                className={classes.popupMenuButton}
                color="inherit"
                aria-owns={
                  menuState.managementOpen ? 'management-menu' : undefined
                }
                aria-haspopup="true"
                onClick={openManagementMenu}
                onMouseEnter={openManagementMenu}
                onMouseLeave={closeManagementMenu}
              >
                <Typography
                  variant="body1"
                  style={{ color: 'white', marginRight: '8px' }}
                >
                  Management
                </Typography>
                <CaretDown size={24} />
              </Button>
              <Menu
                id="management-menu"
                anchorEl={managementAnchor.current}
                open={menuState.managementOpen}
                onClose={closeManagementMenu}
                MenuListProps={{
                  onMouseLeave: closeManagementMenu,
                  style: { minWidth: managementAnchor?.current?.offsetWidth }
                }}
                PaperProps={{
                  onMouseEnter: openManagementMenu,
                  onMouseLeave: closeManagementMenu
                }}
                PopoverClasses={{
                  root: classes.popover,
                  paper: classes.popoverContent
                }}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transitionDuration={0}
              >
                <MenuItem component={RouterLink} to="/management/companies">
                  Companies
                </MenuItem>
                <MenuItem component={RouterLink} to="/management/users">
                  Users
                </MenuItem>
                {featureToggle('FEATURE_TOGGLE_METRICS_DASHBOARD') && (
                  <MenuItem component={RouterLink} to="/management/metrics">
                    Metrics
                  </MenuItem>
                )}
              </Menu>
            </div>
          </Toolbar>
        ))}
      <Toolbar>
        <IconButton
          sx={{ display: { xs: 'block', lg: 'none' } }}
          className={classes.menuButton}
          color="inherit"
          onClick={onOpenNavBarMobile}
        >
          <MenuIcon />
        </IconButton>
        <div
          className={classes.logo}
          onClick={event => handleClickOnLogo(event)}
        >
          <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
            <img src={brandIcon} alt="Seafood portal" />
          </Box>
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
            <img src={brandIcon2} alt="Seafood portal" />
          </Box>
        </div>

        <div className={classes.flexGrow} />
        <Box
          sx={{
            display: { xs: 'none', md: 'inline-flex', alignSelf: 'stretch' }
          }}
        />
        <div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              window.location.href = process.env.REACT_APP_LOGON_APP_URL;
            }}
          >
            Login
          </Button>
        </div>
      </Toolbar>
      <Divider />
    </AppBar>
  );
}

TopBarGuest.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    boUserId: state.auth.boUserId,
    companyName: state.auth.user.companyName,
    firstName: state.auth.user.firstName,
    lastName: state.auth.user.lastName,
    numTotal: state.notifications.numTotal,
    numUnread: state.notifications.numUnread,
    notifications: state.notifications.items,
    permissionMap: state.auth.user.permissionMap
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...notificationActions }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBarGuest);
