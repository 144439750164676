import * as actionTypes from "actions/actionTypes";
import * as helperActions from "actions/helperActions";
import { companyClient } from "../../../services/http";

export const ACTION_PREFIX_COMPANIES = "COMPANIES";

export const fetchCompanies = () => dispatch => {
  dispatch({type: ACTION_PREFIX_COMPANIES + actionTypes.LOAD});
  companyClient.companiesBackoffice.list({})
    .then(response => {
      response.data.result.sort((a, b) => {
        const aCreated = a.metaData.createdAt;
        const bCreated = b.metaData.createdAt;

        return aCreated - bCreated
      })
      dispatch({type: ACTION_PREFIX_COMPANIES + actionTypes.LOADED, payload: { items: response.data.result }});
      dispatch({type: ACTION_PREFIX_COMPANIES + "_FILTER"});
      return response;
    })
    .catch(err => {
      console.log('fetchCompanies error', err);
      dispatch({type: ACTION_PREFIX_COMPANIES + actionTypes.LOAD_FAILED, payload: { meta: err }});
      return Promise.reject(err);
    });
};

export const updateFilter = filter => dispatch => {
  dispatch({type: ACTION_PREFIX_COMPANIES + "_UPDATE_FILTER", payload: { filter }});
  dispatch({type: ACTION_PREFIX_COMPANIES + "_FILTER"});
};

export const updateRecipients = (id, recipientsData) => dispatch => {
  companyClient.companies.update({...recipientsData})
    .then(() => {
      window.location.reload();
      dispatch(helperActions.successMessage('Recipients updated successfully'));
    })
    .catch(err => {
      console.log('approve error', err);
      dispatch(helperActions.errorMessage('Operation failed'));
    });
};
