import {LISTING_FILTER, RESET_FILTER} from "./listingFilterActions";
import {statuses} from "../../../store/constants";

export const HIERARCHY = "HIERARCHY";
export const SELECTING_PATH = "SELECTING_PATH";
export const SET_PATH = "SET_PATH";
export const RESET_PATH = "RESET_PATH";

export const setHierarchyPath = (newHierarchyPath) => (dispatch, getState) => {
  dispatch({ type: HIERARCHY + SET_PATH, payload: {id: newHierarchyPath[newHierarchyPath.length-1], path:newHierarchyPath}});
  if (getState().listingFilter && getState().listingFilter.status === statuses.LOADED){
    dispatch({ type: LISTING_FILTER + RESET_FILTER,
      payload: {
        userProfile: getState().userProfile,
        selectedHierarchy: newHierarchyPath[newHierarchyPath.length-1],
        productTypes: getState().settings.productTypes,
        productTypesHierarchyMap: getState().settings.productTypesHierarchyMap,
      },
    });
  }
}

export const setHierarchyPath_init = (newHierarchyPath) => (dispatch, getState) => {
  dispatch({ type: HIERARCHY + SET_PATH, payload: {id: newHierarchyPath[newHierarchyPath.length-1], path:newHierarchyPath}});
}

export const setSelecting = (selecting) => dispatch => {
  dispatch({ type: HIERARCHY + SELECTING_PATH, payload: selecting});
}