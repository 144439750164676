import authReducer from "./authReducer";
import helperReducer from "./helperReducer";
import navigationReducer from "./navigationReducer";
import settingsReducer from "./settingsReducer";

import companyReducer from "../modules/management/reducers/companyReducer";
import usersReducer from "../modules/management/reducers/usersReducer";
import notificationsReducer from "../modules/notifications/reducers/notificationsReducer";

import userProfileReducer from "../modules/listings/reducers/userProfileReducer";
import listingFilterReducer from "../modules/listings/reducers/listingFilterReducer";
import hierarchyReducer from "../modules/listings/reducers/hierarchyReducer";
import regionsFilterReducer from "../modules/listings/reducers/regionsFilterReducer";

import totalPriceReducer from './totalPriceReducer';

export default {
  auth: authReducer,
  navigation: navigationReducer,
  helper: helperReducer,
  settings: settingsReducer,
  totalPrice: totalPriceReducer,
  companies: companyReducer,
  notifications: notificationsReducer,
  users: usersReducer,
  userProfile: userProfileReducer,
  listingFilter: listingFilterReducer,
  regionsFilter: regionsFilterReducer,
  hierarchy: hierarchyReducer
};
