export const LOAD = "_LOAD";
export const LOADED = "_LOADED";
export const LOAD_FAILED = "_LOAD_FAILED";
export const SAVE = "_SAVE";
export const SAVED = "_SAVED";
export const SAVE_FAILED = "_SAVE_FAILED";
export const DELETE = "_DELETE";
export const DELETED = "_DELETED";
export const DELETE_FAILED = "_DELETE_FAILED";

export const FORM_CLEAR = "_FORM_CLEAR";
export const FORM_UPDATE = "_FORM_UPDATE";
export const FORM_SET = "_FORM_SET";
export const FORM_ERRORS_UPDATE = "_FORM_ERRORS_UPDATE";
export const FORM_ERRORS_SET = "_FORM_ERRORS_SET";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGGED_IN = "AUTH_LOGGED_IN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_LOGGED_OUT = "AUTH_LOGGED_OUT";
export const AUTH_VALIDATE = "AUTH_VALIDATE";

export const AUTH_ON_BEHALF_LOG_IN = "AUTH_ON_BEHALF_LOG_IN";
export const AUTH_ON_BEHALF_LOGGED_IN = "AUTH_ON_BEHALF_LOGGED_IN";
export const AUTH_ON_BEHALF_LOG_IN_FAILED = "AUTH_ON_BEHALF_LOG_IN_FAILED";
export const AUTH_ON_BEHALF_LOGOUT = "AUTH_ON_BEHALF_LOGOUT";
export const AUTH_ON_BEHALF_LOGGED_OUT = "AUTH_ON_BEHALF_LOGGED_OUT";

export const NAVIGATION_CONFIG_CREATE = "NAVIGATION_CONFIG_CREATE";
export const NAVIGATION_CONFIG_CREATED = "NAVIGATION_CONFIG_CREATED";
