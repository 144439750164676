import * as actionTypes from "./actionTypes";
import {listingsClient} from "services/http";
import {
  GENERATE_FILTER_OBJECT,
  GENERATE_REGION_OBJECT,
  LISTING_FILTER,
  REGION_FILTER
} from "../modules/listings/actions";

export const ACTION_PREFIX2 = "SETTINGS2";
export const SET_CONTACT_GROUPS = "SET_CONTACT_GROUPS"
export const SET_LOCATIONS = "SET_LOCATIONS"

export const settings2Load = () => (dispatch) => {
  dispatch({type: ACTION_PREFIX2 + actionTypes.LOAD});
  dispatch({type: LISTING_FILTER + GENERATE_FILTER_OBJECT + actionTypes.LOAD});
  dispatch({type: REGION_FILTER + GENERATE_REGION_OBJECT + actionTypes.LOAD});
  listingsClient.settings.settings.list()
    .then(response => {
      const data = response.data;
      dispatch({type: ACTION_PREFIX2 + actionTypes.LOADED, payload: data});
      dispatch({
        type: LISTING_FILTER + GENERATE_FILTER_OBJECT + actionTypes.LOADED,
        payload: {
          productTypes: data.productTypes,
        }
      });
      dispatch({
        type: REGION_FILTER + GENERATE_REGION_OBJECT,
        payload: {
          regionsCountries: data.regionsCountries,
        }
      });
      return data;
    })
    .catch(err => {
      console.log(err);
      dispatch({type: ACTION_PREFIX2 + actionTypes.LOAD_FAILED, payload: err});
      return Promise.reject(err);
    });
};

export const updateContactGroups = (dispatch) => {
  dispatch({type: SET_CONTACT_GROUPS + actionTypes.LOAD});
  listingsClient.settings.settings.list()
  .then(response => {
    const data = response.data;
    dispatch({type: SET_CONTACT_GROUPS + actionTypes.LOADED, payload: {contactGroups: data.contactGroups}});
  })
  .catch(err => {
    dispatch({type: SET_CONTACT_GROUPS + actionTypes.LOAD_FAILED, payload: err});
  });
}
