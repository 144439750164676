import * as actionTypes from "actions/actionTypes";

import {companyClient} from "../../../services/http";
import {getConfig, setConfig} from "../../../services/config/user.config.service";

export const ACTION_PREFIX = "NOTIFICATIONS";

export const getLastCheckedNotifications = () => {
  return new Promise((resolve, reject) => {
    const time = getConfig("lastCheckedNotifications");
    resolve(time);
  });
};

export const setLastCheckedNotifications = () => {
  return new Promise((resolve, reject) => {
    const time = Date.now();
    setConfig("lastCheckedNotifications", time);
    resolve(time);
  });
};


export const loadNotifications = () => dispatch => {
  dispatch({ type: ACTION_PREFIX + actionTypes.LOAD });
  return companyClient.usersLog.fetchNotificationLogs()
    .then(data => {
      dispatch({ type: ACTION_PREFIX + actionTypes.LOADED, payload: data.data });
      return data;
    })
    .catch(err => {
      //console.log(err);
      dispatch({ type: ACTION_PREFIX + actionTypes.LOAD_FAILED, payload: err });
      return Promise.reject(err);
    });
};

export const markAsRead = (notLogId) => dispatch => {
  return companyClient.usersLog.markNotificationLogAsRead(notLogId)
    .then(data => {
      dispatch(loadNotifications());
    })
    .catch(err => {
      console.log(err);
    });
};

export const markAllAsRead = () => dispatch => {
  return companyClient.usersLog.markAllNotificationLogsAsRead()
    .then(data => {
      dispatch(loadNotifications());
    })
    .catch(err => {
      console.log(err);
    });
};

export const loadLastCheckedNotifications = () => dispatch => {
  return getLastCheckedNotifications()
    .then(data => {
      dispatch({ type: ACTION_PREFIX + "_LOAD_LAST_CHECKED", payload: data });
      return data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

export const updateLastCheckedNotifications = () => dispatch => {
  return setLastCheckedNotifications()
    .then(data => {
      dispatch({ type: ACTION_PREFIX + "_UPDATE_LAST_CHECKED", payload: data });
      return data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
};
