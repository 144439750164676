export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const message = message => {
  return enqueueSnackbar({
    message: message,
    options: {
      key: new Date().getTime() + Math.random()
    }
  });
};

export const successMessage = message => {
  return enqueueSnackbar({
    message: message,
    options: {
      key: new Date().getTime() + Math.random(),
      variant: 'success',
      autoHideDuration: 5000,
      disableWindowBlurListener: true
    }
  });
};

export const infoMessage = message => {
  return enqueueSnackbar({
    message: message,
    options: {
      key: new Date().getTime() + Math.random(),
      variant: 'info',
      autoHideDuration: 5000,
      disableWindowBlurListener: true
    }
  });
};

export const errorMessage = message => {
  return enqueueSnackbar({
    message: message,
    options: {
      key: new Date().getTime() + Math.random(),
      variant: 'error'
    }
  });
};

export const serverErrorMessage = message => {
  return enqueueSnackbar({
    message: message,
    options: {
      key: new Date().getTime() + Math.random(),
      variant: 'error',
      autoHideDuration: 60000,
      resumeHideDuration: 2
    }
  });
};


export const enqueueSnackbar = notification => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});

