import { colors } from '@material-ui/core';

export default {
  DRAFT: colors.lime[800],
  ACTIVE:  colors.green[600],
  FULFILLED:colors.blue[600],
  WITHDRAWN: colors.orange[600],
  PENDING: colors.blue[600],
  CANCELLED:colors.red[600],
  REJECTED: colors.red[600],
  BLACKLISTED:colors.red[800],
  NOT_WHITELISTED: colors.red[800],
  DELETED: colors.red[800],
};
