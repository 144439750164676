import { colors } from '@material-ui/core';
import listings from './listings';
import statuses from './statuses';
// import chat from './chat';
import form from "./form";

const white = '#FFFFFF';
const black = '#000000';

const primary = {
  contrastText: white,
  dark: '#02513E',
  main: '#038767',
  light: '#9ACFC2',
  50: '#F3F9F7',
  70: '#E4F4F1',
  100: '#CDE7E1',
  200: '#9ACFC2',
  300: '#68B7A4',
  400: '#359F85',
  500: '#038767',
  600: '#026C52',
  700: '#02513E',
  800: '#01372A',
  900: '#01281F',
}

const chat = {
  owner: primary.main,
  notOwner: colors.grey[100],
  label: primary.main,
  notOwnerLabel: colors.grey[300]
};

const palette = {
  black,
  white,
  listings,
  statuses,
  chat,
  form,
  primary: {
    contrastText: white,
    dark: '#02513E',
    main: '#038767',
    light: '#9ACFC2',
    50: '#F3F9F7',
    70: '#E4F4F1',
    100: '#CDE7E1',
    200: '#9ACFC2',
    300: '#68B7A4',
    400: '#359F85',
    500: '#038767',
    600: '#026C52',
    700: '#02513E',
    800: '#01372A',
    900: '#01281F'
  },
  critical: {
    50: '#FFF1F2',
    70: '#FFEDEE',
    100: '#FFE4E6',
    200: '#FECDD3',
    300: '#FDA4AF',
    400: '#FB7185',
    500: '#F43F5E',
    600: '#E11D48',
    700: '#BE123C',
    800: '#9F1239',
    900: '#881337'
  },
  caution: {
    50: '#FFFBEB',
    70: '#FFF7D8',
    100: '#FEF3C7',
    200: '#FDE68A',
    300: '#FCD34D',
    400: '#FBBF24',
    500: '#F59E0B',
    600: '#D97706',
    700: '#B45309',
    800: '#92400E',
    900: '#78350F'
  },
  secondary: {
    contrastText: white,
    dark: primary[700],
    main: primary[500],
    light: primary[200]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: '#262626',
    secondary: '#737373',
    tertiary: '#A3A3A3',
    brand: '#038767',
    brandDarker: '#026C52',
    critical: '#E11D48',
    criticalDarker: '#BE123C',
    caution: '#D97706',
    cautionDarker: '#B45309',
    link: primary[500]
  },
  link: primary[500],
  icon: primary[500],
  background: {
    default: '#F2F2F3',
    paper: white,
    white,
    light: '#FAFAFA',
    disabled: '#E5E5E5',
    brandLighter: '#359F85',
    brand: '#038767',
    brandDarker: '#026C52',
    caution: '#D97706',
    cautionDarker: '#B45309',
    danger: '#E11D48',
    dangerDarker: '#BE123C'
  },
  surface: {
    grey: '#FAFAFA',
    greyDarker: '#F5F5F5',
    brand: '#F3F9F7',
    brandDarker: '#E4F4F1',
    critical: '#FFF1F2',
    criticalDarker: '#FFE4E6',
    caution: '#FFFBEB',
    cautionDarker: '#FEF3C7'
  },
  divider: colors.grey[200],
  lightGrey: '#F5F5F5'
};

export default palette
