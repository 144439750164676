import {companyClient} from "services/http";

const TOKEN = "token";
const BO_TOKEN = "BOtoken";
const REDIRECT = "redirect";
const REQUEST_CODE = "request_code";
export const GUEST_TOKEN = 'asdf';

export const isLoggedOn = () => {
  return getToken() || getBOToken();
};

export const getRequestCode = () => {
  let rc = localStorage.getItem(REQUEST_CODE);
  if (!rc || Date.now() - parseInt(rc) > 3600000) {
    rc = Date.now();
    localStorage.setItem(REQUEST_CODE, rc);
  }
  return rc;
};

export const getToken = () => {
  const realtoken = localStorage.getItem(TOKEN);
  if (realtoken) {
    return realtoken;
  } else {
    // localStorage.setItem(TOKEN,'asdf')
    return GUEST_TOKEN;
  }
};

export const setToken = token => {
  localStorage.setItem(TOKEN, token);
};

const getBOToken = () => {
  const realtoken = localStorage.getItem(BO_TOKEN);
  if (realtoken) {
    return realtoken;
  } else {
    return 'asdf';
  }
};

export const getOnBehalfToken = () => {
  return localStorage.getItem("_" + TOKEN);
};

export const removeTokens = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem("_" + TOKEN);
  localStorage.removeItem(BO_TOKEN);
  localStorage.removeItem("_" + BO_TOKEN);
};

const backupToken = (tokenKey) => {
  const token = localStorage.getItem(tokenKey);
  if (token) {
    localStorage.removeItem(tokenKey);
    localStorage.setItem("_" + tokenKey, token);
  }
};

const backupTokens = () => {
  backupToken(TOKEN);
  backupToken(BO_TOKEN);
};

const restoreToken = (tokenKey) => {
  const token = localStorage.getItem("_" + tokenKey);
  if (token) {
    localStorage.removeItem("_" + tokenKey);
    localStorage.setItem(tokenKey, token);
  }
};

const restoreTokens = () => {
  restoreToken(TOKEN);
  restoreToken(BO_TOKEN);
};

export const onBehalfLogIn = (token) => {
  backupTokens();
  setToken(token);
};

export const onBehalfLogOut = () => {
  restoreTokens();
};

export const checkStatus = (accessToken, refresh) => {
  return companyClient.security.validate({ accessToken, refresh })
};

export const loginOnBehalf = companyId => {
  return companyClient.security.onbehalf({ companyId })
};

export const resetPassword = email => {
  return companyClient.security.forgot({email: String(email).toLowerCase()});
};

export const setRedirect = (redirect) => {
  return localStorage.setItem(REDIRECT, redirect);
};

export const clearRedirect = () => {
  return localStorage.removeItem(REDIRECT);
};
