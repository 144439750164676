import * as actionTypes from "./actionTypes";
import navigationConfig from "./navigationConfig";

const environment = process.env.REACT_APP_ENV;

export const TARGET_ORIGIN_MAP = {
  'backoffice': process.env.REACT_APP_BACKOFFICE_URL
};

export const createNavigation = (user) => dispatch => {
  dispatch({type: actionTypes.NAVIGATION_CONFIG_CREATE});

  const checkPermission = (acceptedPermission, permissionsMap) => {
    return !acceptedPermission ||
      (Array.isArray(acceptedPermission) ? acceptedPermission : [acceptedPermission])
        .filter(p => permissionsMap[p])
        .length > 0;
  };

  const checkEnvironment = (acceptedEnvironment) => {
    return !acceptedEnvironment ||
      (Array.isArray(acceptedEnvironment) ? acceptedEnvironment : [acceptedEnvironment])
        .filter(e => e === environment)
        .length > 0;
  };

  const check = (acceptedPermission, permissionsMap, acceptedEnvironment) => {
    return checkPermission(acceptedPermission, permissionsMap) && checkEnvironment(acceptedEnvironment);
  };

  const navConf = [];

  for (let curNavConf of navigationConfig) {
    let pages = [];
    for (let curPage of curNavConf.items) {
      if (check(curPage.permissions, user.permissionsMap, curPage.environment)) {
        let items = curPage.items ?
          curPage.items
            .filter(ch => {
              return check(ch.permissions, user.permissionsMap, ch.environment);
            }) : undefined;
        if (!curPage.items || (curPage.items && items.length > 0)) {
          pages.push({
            ...curPage,
            items: items
          });
        }
      }
    }

    if (pages.length > 0) {
      navConf.push({
        subheader: curNavConf.subheader,
        items: pages
      });
    }
  }

  dispatch({type: actionTypes.NAVIGATION_CONFIG_CREATED, payload: navConf});
};


