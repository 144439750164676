import {
  RESET_TOTAL_PRICE,
  SET_TOTAL_PRICE
} from '../actions/totalPriceActions';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_TOTAL_PRICE:
      return initialState;

    case SET_TOTAL_PRICE: {
      return action.payload;
    }

    default:
      return state;
  }
};
