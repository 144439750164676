import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Button,
  Toolbar,
  Hidden,
  colors,
} from '@material-ui/core';

import brandIcon from "assets/img/logo106x42.png";
import brandIcon2 from "assets/img/logofish66x20.png";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1
  },
  logo: {
    paddingRight: theme.spacing(6),
  },
  popupMenuFirst: {
    paddingLeft: theme.spacing(6),
    backgroundColor: colors.white,
  },
  popupMenu: {
    paddingLeft: theme.spacing(1),
    backgroundColor: colors.white,
  },
  popupMenuButton: {
    textTransform: 'none',
  },
}));

function TopBar({ permissionMap, className, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar>
        <div className={classes.logo}>
          <Hidden mdDown>
            <img src={brandIcon} alt="Seafood portal"/>
          </Hidden>
          <Hidden lgUp>
            <img src={brandIcon2} alt="Seafood portal"/>
          </Hidden>
        </div>
        <div className={classes.flexGrow}/>
        <div className={classes.popupMenu}>
          <Button
            className={classes.popupMenuButton}
            color="inherit"
            aria-haspopup="true"
            onClick={() => {
              window.location.href = process.env.REACT_APP_SIGNUP_APP_URL;
            }}
          >
            Register
          </Button>
        </div>
        <div className={classes.popupMenu}>
          <Button
            className={classes.popupMenuButton}
            color="inherit"
            aria-haspopup="true"
            onClick={() => {
              window.location.href = process.env.REACT_APP_LOGON_APP_URL;
            }}
          >
            Log in
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
