import * as actionTypes from "actions/actionTypes";
import { history } from "store/configureStore";
import { companyClient } from "../../../services/http";

export const ACTION_PREFIX_USERS = "USERS";
export const ACTION_PREFIX = "USER";

export const fetchUsers = () => dispatch => {
  dispatch({ type: ACTION_PREFIX_USERS + actionTypes.LOAD });
  companyClient.usersBackoffice.list({})
    .then(response => {
      dispatch({ type: ACTION_PREFIX_USERS + actionTypes.LOADED, payload: {items: response.data.result} });
      dispatch({ type: ACTION_PREFIX_USERS + "_FILTER" });
      return response;
    })
    .catch(err => {
      console.log('fetchUsers error', err);
      dispatch({ type: ACTION_PREFIX_USERS + actionTypes.LOAD_FAILED, payload: {meta: err} });
      return Promise.reject(err);
    });
};

export const fetchUsersAsCompany = () => dispatch => {
  dispatch({ type: ACTION_PREFIX_USERS + actionTypes.LOAD });
  companyClient.usersAdmin.list()
    .then(response => {
      dispatch({ type: ACTION_PREFIX_USERS + actionTypes.LOADED, payload: {items: response.data.result} });
      dispatch({ type: ACTION_PREFIX_USERS + "_FILTER" });
      return response;
    })
    .catch(err => {
      console.log('fetchUsers error', err);
      dispatch({ type: ACTION_PREFIX_USERS + actionTypes.LOAD_FAILED, payload: {meta: err} });
      return Promise.reject(err);
    });
};

export const updateFilter = filter => dispatch => {
  dispatch({ type: ACTION_PREFIX_USERS + "_UPDATE_FILTER", payload: {filter: filter} });
  dispatch({ type: ACTION_PREFIX_USERS + "_FILTER" });
};

export const managementUsers = () => dispatch => {
  history.push("/management/users")
};
