import {statuses} from "store/constants";
import * as actionTypes from "actions/actionTypes";
import {DELETE_FILTER, SAVE_FILTER, SELECT_FILTER, USER_PROFILE, RESET_FILTER, SELECTING_FILTER} from "../actions";

const initialState = {
  selectedFilter: null,
  filters: [],
  status: statuses.IDLE,
  meta: null,
  profileHierarchyPath: [],
  selecting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {

    case USER_PROFILE + actionTypes.LOAD: {
      return {
        ...state,
        status: statuses.LOADING,
        meta: ""
      };
    }

    case USER_PROFILE + actionTypes.LOADED: {
      if (!action.payload.selectedFilter) {
        return {
          ...state,
          selectedFilter: action.payload.selectedFilter,
          filters: action.payload.filters ? action.payload.filters : {},
          status: statuses.LOADED,
          profileHierarchyPath: [],
          meta: ""
        };
      }

      const profileFilter = action.payload.filters[action.payload.selectedFilter] || undefined;
      return {
        ...state,
        selectedFilter: action.payload.selectedFilter,
        filters: action.payload.filters ? action.payload.filters : {},
        status: statuses.LOADED,
        profileHierarchyPath: profileFilter ? profileFilter.productHierarchy.path : [],
        meta: ""
      };
    }

    case USER_PROFILE + actionTypes.LOAD_FAILED: {
      return {
        ...state,
        status: statuses.FAILED,
        meta: action.payload
      };
    }

    case USER_PROFILE + RESET_FILTER: {
      return {
        ...state,
        currentHierarchyPath: [],
      };
    }

    case USER_PROFILE + SELECT_FILTER: {
      return {
        ...state,
        currentHierarchyPath: action.payload,
      }
    }

    case USER_PROFILE + SELECTING_FILTER: {
      return {
        ...state,
        selecting: action.payload,
      }
    }

    case USER_PROFILE + DELETE_FILTER: {
      return {
        ...state,
        currentHierarchyPath: action.payload,
      }
    }


    case USER_PROFILE + SAVE_FILTER: {
      return {
        ...state,
        currentHierarchyPath: action.payload,
      }
    }

    default:
      return state;
  }
};
