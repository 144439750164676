const PREFIX = "user.config";

export const getConfig = (key) => {
  const value = localStorage.getItem(getKey(key));
  return value ? JSON.parse(value) : undefined;
};

export const setConfig = (key, value) => {
  const theKey = getKey(key);
  if (value) {
    const json = JSON.stringify(value);
    localStorage.setItem(theKey, json);
  } else {
    localStorage.removeItem(theKey);
  }
};

const getKey = key => PREFIX + '_' + key;

export const setEdit = () => {
  localStorage.setItem('edit', '1');
}

export const getEdit = () => {
  const edit = localStorage.getItem('edit') === '1';
  localStorage.removeItem('edit');
  return edit;
}

export const setChatOpen = () => {
  localStorage.setItem('chatOpen', '1');
}

export const getChatOpen = () => {
  const edit = localStorage.getItem('chatOpen') === '1';
  localStorage.removeItem('chatOpen');
  return edit;
}

export const markThreadVisisted = id => {
  const threadsVisited = getThreadsVisisted();
  threadsVisited[id] = Date.now();
  setConfig('threadsVisited', threadsVisited);
};

const getThreadsVisisted = () => {
  let threadsVisited = getConfig('threadsVisited');
  if (!threadsVisited) {
    threadsVisited = {};
  }
  return threadsVisited;
}

export const threadVisisted = (id, updatedAt) => {
  const threadsVisited = getThreadsVisisted();
  return threadsVisited[id] >= updatedAt;
};

export const clearThreadsVisisted = (response) => {
  const threadIds = new Set([...response.seller, ...response.buyer]
    .map(item => item.threads)
    .flat()
    .filter(t => !!t)
    .map(thread => thread.id));
  const threadsVisited = getThreadsVisisted();
  const ids = Object.keys(threadsVisited);
  for (const id of ids) {
    if (!threadIds.has(id)) {
      delete threadsVisited[id];
    }
  }
  setConfig('threadsVisited', threadsVisited);
};
