import React, {Suspense, useState} from 'react';
import {renderRoutes} from 'react-router-config';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/styles';
import {LinearProgress} from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import Footer from 'components/Footer'
import { isLoggedOn } from 'services/auth/auth.service';
import TopBarGuest from './TopBarGuest';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    // paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    // overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  }
}));

function Dashboard({ route, history }) {
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const token = isLoggedOn();

  return (
    <>
      {token && token.length > 10 ? (
        <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      ) : (
        <TopBarGuest onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      )}
      {/* <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
      /> */}
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
      <Footer />
    </>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
