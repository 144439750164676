import * as actionTypes from "actions/actionTypes";
import {companyClient} from "services/http";
import {HIERARCHY, SET_PATH} from "./hierarchyActions";
import {GENERATE_FILTER_OBJECT, LISTING_FILTER, WITH_SELECTED_FILTER} from "./listingFilterActions";
import {GENERATE_REGION_OBJECT, REGION_FILTER, SET_REGION, WITH_SELECTED_REGIONS} from "./regionsFilterActions";

export const USER_PROFILE = "USER_PROFILE";
export const SELECT_FILTER = "SELECT_FILTER";
export const SELECTING_FILTER = "SELECTING_FILTER";
export const SAVE_FILTER = "SAVE_FILTER";
export const DELETE_FILTER = "DELETE_FILTER";


export const loadUserProfile = () => dispatch => {
  dispatch({ type: USER_PROFILE + actionTypes.LOAD });
  return companyClient.profile.getProfile()
    .then(response => {
      const profile = response.data;
      dispatch({ type: USER_PROFILE + actionTypes.LOADED, payload: profile });
      return profile;
    })
    .catch(err => {
      //console.log(err);
      dispatch({ type: USER_PROFILE + actionTypes.LOAD_FAILED, payload: err });
      return Promise.reject(err);
    });
};

export const updateUserProfileSelectedFilter = (id) => (dispatch, getState) => {
  return companyClient.profile.updateSelectedFilter(id)
    .then(response => {
      const profile = response.data;
      const filter = profile.filters[profile.selectedFilter];
      dispatch({type: HIERARCHY + SET_PATH, payload: filter.productHierarchy});
      dispatch({
        type: REGION_FILTER + GENERATE_REGION_OBJECT + WITH_SELECTED_REGIONS,
        payload: {
          regionsCountries: getState().settings.regionsCountries,
          regions: filter.regions,
        }
      })
      dispatch({type: LISTING_FILTER + GENERATE_FILTER_OBJECT + WITH_SELECTED_FILTER,
        payload: {
          args: {
            productTypes: getState().settings.productTypes,
            selectedHierarchy: filter.productHierarchy.id,
            productTypesHierarchyMap: getState().settings.productTypesHierarchyMap,
          },
          selectedFilter: filter,
        }
      });
      dispatch({ type: USER_PROFILE + actionTypes.LOADED, payload: profile });
      return profile;
    })
    .catch(err => {
      //console.log(err);
      return Promise.reject(err);
    });
};

export const renameProfileFilter = (filters, selectedFilter, oldName, newName) => dispatch => {
  return companyClient.profile.updateProfile({
    filter: {
      ...filters[oldName],
      id: newName,
    },
    selectedFilter: (oldName === selectedFilter) ? newName : selectedFilter,
    deleteFilterId: oldName,
  }).then(response => {
    const data = response.data;
    dispatch({ type: USER_PROFILE + actionTypes.LOADED, payload: data });
    return data
  }).catch(err => {
    //console.log(err);
    return Promise.reject(err);
  });
}

export const deleteProfileFilter = (id) => dispatch =>  {
  return companyClient.profile.deleteFilter(id)
      .then(response => {
        const data = response.data;
        dispatch({ type: USER_PROFILE + actionTypes.LOADED, payload: data });
        return data;
      })
      .catch(err => {
        //console.log(err);
        return Promise.reject(err);
      });
}

//Driver function for creating a new profile
export const saveNewProfileFilter = (name, filterIds, hierarchyPath, regions) => dispatch => {
  return updateUserProfileFilter({
    selectedFilter: name,
    filter: {
      id: name,
      productHierarchy: {
        id: hierarchyPath[hierarchyPath.length - 1],
        path: hierarchyPath,
      },
      regions: regions,
      filterIds: filterIds,
    }
  })(dispatch);
};

export const updateUserProfileFilter = (filter) => dispatch => {
  return companyClient.profile.updateProfile(filter)
    .then(response => {
      const data = response.data;
      dispatch({ type: USER_PROFILE + actionTypes.LOADED, payload: data });
      return data;
    })
    .catch(err => {
      //console.log(err);
      return Promise.reject(err);
    });
};