export const RESET_TOTAL_PRICE = 'RESET_TOTAL_PRICE';
export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE';

export const resetTotalPrice = dispatch => {
  dispatch({
    type: RESET_TOTAL_PRICE,
    totalPrice: null
  });
};

export const setTotalPrice = payload => dispatch => {
  dispatch({
    type: SET_TOTAL_PRICE,
    payload
  });
};
