/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import BarChartIcon from '@material-ui/icons/BarChart';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import {perm as permissions} from '@jetseafoodas/sfp-types';
import {featureToggle} from '../featureToggles';

export default [
  {
    subheader: 'Pages',
    items: [
      {
        title: 'Notifications',
        href: '/notifications',
        icon: NotificationsIcon,
      },
      {
        title: 'Dashboard',
        permissions: [
          permissions.SEC_ALL_DATA_LISTING,
          permissions.SEC_PUBLIC_LISTING,
          permissions.SEC_OWNER_LISTING,
          permissions.SEC_OWNER_RFQ,
        ],
        href: '/dashboard/company',
        icon: DashboardIcon,
      },
      {
        title: 'Market',
        href: '/marketplace1',
        icon: HomeIcon,
        items: [
          {
            title: 'Listings',
            permissions: [
              permissions.SEC_PUBLIC_LISTING,
              permissions.SEC_OWNER_LISTING,
              permissions.SEC_ALL_DATA_LISTING,
            ],
            href: '/market/listings',
          },
          {
            title: 'RfQs',
            permissions: [
              permissions.SEC_PUBLIC_RFQ,
              permissions.SEC_OWNER_RFQ,
              permissions.SEC_ALL_DATA_RFQ,
            ],
            href: '/market/rfqs',
          },
        ],
      },
      {
        title: 'Order List',
        href: '/marketplace',
        icon: BarChartIcon,
        items: [
          {
            title: 'My Listings',
            permissions: [permissions.SEC_OWNER_LISTING],
            href: '/listings',
          },
          {
            title: 'My RfQs',
            permissions: [permissions.SEC_OWNER_RFQ],
            href: '/rfqs',
          },
          {
            title: 'My Threads',
            permissions: [
              permissions.SEC_OWNER_LISTING,
              permissions.SEC_OWNER_RFQ,
            ],
            href: '/threads',
          },
          {
            title: 'Order List',
            permissions: [
              permissions.SEC_OWNER_LISTING,
              permissions.SEC_OWNER_RFQ,
            ],
            href: '/orders',
          },
        ],
      },
    ],
  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Settings',
        href: '/settings',
        permissions: [permissions.SEC_MANAGE_COMPANY_SETTINGS],
        icon: SettingsIcon,
        items: [
          {
            title: 'Company settings',
            permissions: [permissions.SEC_MANAGE_COMPANY_SETTINGS],
            href: '/management/companyprofile/overview',
          },
          {
            title: 'Company profile',
            permissions: [permissions.SEC_MANAGE_COMPANY_SETTINGS],
            href: '/profile/management',
          },
          {
            title: 'Contacts',
            permissions: [permissions.SEC_MANAGE_COMPANY_SETTINGS],
            href: '/settings/contacts',
          },
          {
            title: 'Currency',
            permission: [permissions.SEC_MANAGE_COMPANY_SETTINGS],
            href: '/settings/currency',
          },
        ],
      },
      {
        title: 'Market management',
        href: '/man_market',
        icon: BarChartIcon,
        items: [
          {
            title: 'Listings',
            permissions: [permissions.SEC_ALL_DATA_LISTING],
            href: '/listings',
          },
          {
            title: 'RfQs',
            permissions: [permissions.SEC_ALL_DATA_RFQ],
            href: '/rfqs',
          },
          {
            title: 'Threads',
            permissions: [
              permissions.SEC_ALL_DATA_LISTING,
              permissions.SEC_ALL_DATA_RFQ,
            ],
            href: '/threads',
          },
          {
            title: 'Orders',
            permissions: [
              permissions.SEC_ALL_DATA_LISTING,
              permissions.SEC_ALL_DATA_RFQ,
            ],
            href: '/orders',
          },
        ],
      },
      {
        title: 'Management',
        href: '/management',
        icon: BarChartIcon,
        items: [
          {
            title: 'Companies',
            permissions: [permissions.SEC_MANAGE_ANY_USERS],
            href: '/management/companies',
          },
          {
            title: 'Users',
            permissions: [permissions.SEC_MANAGE_ANY_USERS],
            href: '/management/users',
          },
          {
            title: 'Metrics',
            permissions: [
              featureToggle('FEATURE_TOGGLE_METRICS_DASHBOARD')
                ? permissions.SEC_MANAGE_ANY_USERS
                : '',
            ],
            href: '/management/metrics',
          },
        ],
      },
    ],
  },
]
