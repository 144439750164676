import * as actionTypes from "actions/actionTypes";
import {ACTION_PREFIX2, SET_CONTACT_GROUPS, SET_LOCATIONS} from "../actions/settingsActions";
import {statuses} from "store/constants";
import {flatToMap, flatToTree} from "../modules/listings/services/product.filter.service";
import { AddressLocation, Currencies, Regions} from "@jetseafoodas/sfp-types";
import { Groups } from "@jetseafoodas/sfp-company-types";

interface Settings {
  status: string,
  contactGroups: Groups | null,
  currencies: Currencies | null,
  locations: AddressLocation[] | null,
  paymentTerms: Array<{id: string, description: string, short: string}> | null,
  productDefinitions: any,
  productTypes: any,
  productTypesHierarchyMap: {},
  productTypesHierarchyTree: {},
  regionsCountries: Regions,
  regionCodes: Array<{id: string, name: string}> | null,
}

interface SettingsAction {
  type: 'SETTINGS_LOAD' | 'SETTINGS_LOADED' | 'SETTINGS2_LOAD' | 'SETTINGS2_LOADED' | 'SET_LOCATIONS' | 'SET_CONTACT_GROUPS_LOADED'
  payload: Partial<Settings>
}
const initialState = {
  status: statuses.IDLE,
  contactGroups: null,
  currencies: null,
  locations: null,
  paymentTerms: null,
  productDefinitions: null,
  productTypes: null,
  productTypesHierarchyMap: {},
  productTypesHierarchyTree: {},
  regionsCountries: null,
  regionCodes: null,
};

const mapToRegionCodes = (regionsCountries: any ) => {
  const regionCodes : any = [];
  regionsCountries.map((r: any) => {
    regionCodes.push({
      id: r.code,
      name: r.region,
    });
    r.subregions.map((sr: any) => {
      regionCodes.push({
        id: sr.code,
        name: sr.subregion,
      });
      sr.countries.map((c: any) => {
        return regionCodes.push({
          id: c.regionCode,
          name: c.name,
        });
      });
      return 0;
    });
    return 0;
  });
  return regionCodes;
};

export default (state: Settings = initialState, action: SettingsAction): Settings => {
  switch (action.type) {

    case ACTION_PREFIX2 + actionTypes.LOADED:
      return {
        ...state,
        contactGroups: action.payload.contactGroups ?? null,
        currencies: action.payload.currencies ?? null,
        locations: action.payload.locations ?? null,
        paymentTerms: action.payload.paymentTerms ?? null,
        productDefinitions: action.payload.productDefinitions,
        productTypes: action.payload.productTypes,
        productTypesHierarchyMap: flatToMap(action.payload.productTypes.productHierarchy, "parent"),
        productTypesHierarchyTree: flatToTree(action.payload.productTypes.productHierarchy, "parent"),
        regionsCountries: action.payload.regionsCountries,
        regionCodes: mapToRegionCodes(action.payload.regionsCountries),
      };
    
    case SET_CONTACT_GROUPS + actionTypes.LOADED: {
      return {
        ...state,
        contactGroups: action.payload.contactGroups ?? null
      }
    }

    case SET_LOCATIONS: {
      return {
        ...state,
        locations: action.payload.locations ?? null
      }
    }

    default:
      return state;
  }
};
