import { routes as managementRoutes } from "modules/management/views/routes";
import { routes as notificationsRoutes } from "modules/notifications/views/routes";
import { routes as settingsRoutes } from "modules/settings/views/routes";
import { routes as listingsRoutes } from "modules/listings/views/routes";
import { routes as openListingRoutes } from "modules/listings/views/routesopen";
import { routes as analyticsRoutes } from "modules/analytics/views/routes"
import { routes as companyProfileRoutes} from 'modules/company/views/routes'

import RouteView from './layouts/Route';
import DashboardLayout from './layouts/Dashboard';
import OpenLayout from './layouts/Open';

const routes = [
  {
    path: '/',
    exact: true,
    component: RouteView,
  },
  {
    path: '/o/*',
    component: OpenLayout,
    routes: [
      ...openListingRoutes,
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      ...managementRoutes,
      ...notificationsRoutes,
      ...settingsRoutes,
      ...listingsRoutes,
      ...analyticsRoutes,
      ...companyProfileRoutes
    ]
  },
];

export default routes;
