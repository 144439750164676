import {statuses} from "store/constants";
import * as actionTypes from "actions/actionTypes";
import {
  GENERATE_FILTER_OBJECT,
  LISTING_FILTER,
  RESET_FILTER,
  SET_FAVOURITES,
  SET_FILTER,
  SET_ATTRIBUTE,
  WITH_SELECTED_FILTER,
} from "../actions";

const initialState = {
  status: null,
  filters: null,
  favourites: false,
  attributes: {},
};

const sectionToName = (section) => {
  const names = {
    productDefinitions: 'Products',
    certifications: 'Certifications',
    productTypes: 'Product types',
    cutTypes: 'Cut types',
    productionMethods: 'Production methods',
    freezingMethods: 'Freezing methods',
    regionsCountries: 'Regions'
  };
  return names[section] || section;
}

const sectionsToShow = ["productDefinitions", "certifications",
  "productTypes", "cutTypes", "productionMethods", "freezingMethods", "regionsCountries"];

const show = (selectedHierarchySet, section, value) =>
    !(section === "productDefinitions") ||
    !selectedHierarchySet ||
    selectedHierarchySet.has(value.productHierarchyId);

const generateFilter = (
    { productTypes,
      selectedHierarchy = undefined,
      productTypesHierarchyMap = undefined
    },
    selectedFilter = undefined) => {
  if (!productTypes) return {};
  let filterObject = {};
  const selectedHierarchySet = selectedHierarchy && productTypesHierarchyMap && productTypesHierarchyMap[selectedHierarchy];
  const cutTypes = new Set();
  for (const [section, values] of Object.entries(productTypes)) {
    let currObj = {};
    let numSelected = 0;
    for (const value of values) {
      let b = (selectedFilter && selectedFilter.filterIds.includes(value.id));
      if (b) numSelected++;
      const showValue = show(selectedHierarchySet, section, value);
      currObj[value.id] = {
        id: value.id,
        description: value.description,
        checked: !!b,
        show: showValue,
        shrimp: value.shrimp,
        byproducts: value.byproducts,
      };
      if (section === "productDefinitions" && showValue) {
        cutTypes.add(value.productCategory);
      }
    }
    filterObject[section] = {
      name: sectionToName(section),
      numSelected: numSelected,
      filters: currObj,
      showSection: sectionsToShow.includes(section),
    };
  }

  for (const [key, cutType] of Object.entries(filterObject["cutTypes"].filters)) {
    filterObject["cutTypes"]["filters"][key].show =
        (cutTypes.has("FISH") && !cutType.shrimp && !cutType.byproducts) ||
        (cutTypes.has("SHRIMP") && !!cutType.shrimp) ||
        (cutTypes.has("BYPRODUCTS") && !!cutType.byproducts);
  }

  return filterObject;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LISTING_FILTER + GENERATE_FILTER_OBJECT + actionTypes.LOAD: {
      return {
        ...state,
        status: statuses.LOADING,
      };
    }

    case LISTING_FILTER + GENERATE_FILTER_OBJECT + actionTypes.LOADED: {
      return {
        ...state,
        status: statuses.LOADED,
        filters: generateFilter(action.payload),
      };
    }

    case LISTING_FILTER + GENERATE_FILTER_OBJECT + WITH_SELECTED_FILTER: {
      return {
        ...state,
        filters: generateFilter({...action.payload.args}, action.payload.selectedFilter),
      };
    }

    case LISTING_FILTER + SET_FILTER: {
      const {id, section} = action.payload;
      const selected = state.filters[section].filters[id];
      return {
        ...state,
        filters: {
          ...state.filters,
          [section]: {
            numSelected: selected.checked
                ? state.filters[section].numSelected - 1
                : state.filters[section].numSelected + 1,
            filters: {
              ...state.filters[section].filters,
              [id]: {
                ...selected,
                checked: !selected.checked,
              }
            },
            name: sectionToName(section),
            showSection: sectionsToShow.includes(section),
          },
        }
      };
    }

    case LISTING_FILTER + SET_FAVOURITES: {
      return {
        ...state,
        favourites: action.payload.favourites,
      };
    }

    case LISTING_FILTER + SET_ATTRIBUTE: {
      const { attributeId, value } = action.payload;
      return {
        ...state,
        attributes: {
          ...state.attributes,
          [attributeId]: value,
        }
      }
    }

    case LISTING_FILTER + RESET_FILTER: {
      return {
        ...state,
        status: statuses.LOADED,
        filters: generateFilter(action.payload),
        favourites: false,
        attributes: {},
      };
    }

    default:
      return state;
  }
};
