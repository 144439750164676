import * as actionTypes from "actions/actionTypes";
import {ACTION_PREFIX_COMPANIES} from "modules/management/actions";
import {statuses, companyStatuses } from "store/constants";
import { companySegmentArray } from "@jetseafoodas/sfp-types";
import { Company } from "@jetseafoodas/sfp-company-types";
import { PayloadAction } from '@reduxjs/toolkit'

interface FilterType {
  inputValue: string;
  chips: string[];
}

interface CompanyReducerState {
  items: Company[];
  filteredItems: Company[];
  filter: FilterType;
  status: string;
  meta: string | null;
}

const initialState: CompanyReducerState = {
  items: [],
  filteredItems: [],
  filter: {
    inputValue: '',
    chips: []
  },
  status: statuses.IDLE,
  meta: null
};

const filterItems = (items: Company[], filter: FilterType) => {
  const filterChipsCompanyStatuses = filter.chips.filter((chip: string): boolean => companyStatuses.indexOf(chip) >= 0);
  const filterChipsCompanySegments = filter.chips.filter((chip: string): boolean => companySegmentArray.map(cs => cs.description).indexOf(chip) >= 0);
  let filterChipsFree = filter.chips.filter((chip: string): boolean => filterChipsCompanyStatuses.indexOf(chip) < 0 &&
    filterChipsCompanySegments.indexOf(chip) < 0);

  if (filter && filter.inputValue) {
    filterChipsFree.push(filter.inputValue);
  }

  return items
    .filter(item => {

      let ret = true;

      if (filterChipsCompanyStatuses.length > 0) {
        ret = false;
      }
      filterChipsCompanyStatuses.forEach((chip: string): void => {
        if (item.status && item.status.toLowerCase().indexOf(chip.toLowerCase()) >= 0) {
          ret = true;
        }
      });

      if (ret) {
        if (filterChipsCompanySegments.length > 0) {
          ret = false;
        }
        filterChipsCompanySegments.forEach((chip: string): void  => {
          if (item.segment && item.segment.toLowerCase().indexOf(chip.toLowerCase()) >= 0) {
            ret = true;
          }
        });
      }

      filterChipsFree.forEach((chip: string): void => {
        if (!(
          (item.companyName && item.companyName.toLowerCase().indexOf(chip.toLowerCase()) >= 0) ||
          (item.registrationNumber && item.registrationNumber.toLowerCase().indexOf(chip.toLowerCase()) >= 0) ||
          (item.VATNumber && item.VATNumber.toLowerCase().indexOf(chip.toLowerCase()) >= 0)
        )) {
          ret = false;
        }
      });

      return ret;
    });
};

export default (state: CompanyReducerState = initialState, action: PayloadAction<Partial<CompanyReducerState>>): CompanyReducerState => {
  switch (action.type) {

    case ACTION_PREFIX_COMPANIES + actionTypes.LOAD:
      return {
        ...state,
        status: statuses.LOADING,
        meta: ""
      };

    case ACTION_PREFIX_COMPANIES + actionTypes.LOADED:
      return {
        ...state,
        status: statuses.LOADED,
        items: action.payload.items ?? initialState.items,
        meta: ""
      };

    case ACTION_PREFIX_COMPANIES + actionTypes.LOAD_FAILED:
      return {
        ...state,
        status: statuses.FAILED,
        meta: action.payload.meta ?? initialState.meta
      };

    case ACTION_PREFIX_COMPANIES + "_UPDATE_FILTER":
      return {
        ...state,
        filter: action.payload.filter ?? initialState.filter
      };

    case ACTION_PREFIX_COMPANIES + "_FILTER":
      return {
        ...state,
        filteredItems: filterItems(state.items, state.filter)
      };

    default:
      return state;
  }
};
