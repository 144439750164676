import {statuses} from "store/constants";
import * as actionTypes from "actions/actionTypes";
import {GENERATE_REGION_OBJECT, REGION_FILTER, RESET_REGIONS, SET_REGION, WITH_SELECTED_REGIONS} from "../actions";

const initialState = {
  regions: {},
  status: null,
};

const openUpwards = (mo, id) => {
  const elem = mo.filters[id]
  if (elem.parent) {
    const parentElem = mo.filters[elem.parent]
    parentElem.selectedChildren = parentElem.selectedChildren.concat(id);
    parentElem.selected = true;
    parentElem.children.forEach(c => {
      mo.filters[c].show = true;
    })
    openUpwards(mo, elem.parent)
  }
}

const generateRegionsObject = (regionsCountries, profileRegions = undefined) => {
  let newRegions = {
    name: "Regions",
    numSelected: 0,
    filters: {},
    showSection: true
  }

  regionsCountries && regionsCountries.forEach(rc => {
    newRegions.filters[rc.code] = {
      id: rc.code,
      description: rc.region,
      show: true,
      checked: false,
      selected: false,
      tier: 0,
      parent: null,
      children: [],
      selectedChildren: [],
    }
    rc.subregions.forEach(sr => {
      newRegions.filters[rc.code].children.push(sr.code)
      newRegions.filters[sr.code] = {
        id: sr.code,
        description: sr.subregion,
        show: false,
        checked: false,
        selected: false,
        tier: 1,
        parent: rc.code,
        children: [],
        selectedChildren: [],
      }

      sr.countries.forEach(c => {
        newRegions.filters[sr.code].children.push(c.regionCode)
        newRegions.filters[c.regionCode] = {
          id: c.regionCode,
          description: c.name,
          show: false,
          checked: false,
          selected: false,
          tier: 2,
          parent: sr.code,
          children: [],
          selectedChildren: [],
        }
      })
    })
  })

  profileRegions && profileRegions.forEach(sf => {
    const mo = newRegions
    const filterElem = mo.filters[sf];
    if (filterElem) {
      filterElem.show = true;
      filterElem.children.forEach(c => {
        mo.filters[c].show = true;
      })
      if (filterElem.selectedChildren.length === 0) {
        filterElem.checked = true;
      }
      filterElem.selected = true;
      openUpwards(mo, sf)
    }
  })

  let numSelected = 0;
  for (const {checked, selected} of Object.values(newRegions.filters)) {
    if (checked && selected) {
      numSelected++;
    }
  }

  newRegions.numSelected = numSelected;
  return newRegions;
}

export default (state = initialState, action) => {
  switch (action.type) {

    case REGION_FILTER + GENERATE_REGION_OBJECT + actionTypes.LOAD: {
      return {
        ...state,
        status: statuses.LOADING,
      };
    }

    case REGION_FILTER + GENERATE_REGION_OBJECT: {
      const {regionsCountries} = action.payload;
      return {
        ...state,
        regions: generateRegionsObject(regionsCountries),
        status: statuses.LOADED,
      };
    }

    case REGION_FILTER + GENERATE_REGION_OBJECT + WITH_SELECTED_REGIONS: {
      const {regionsCountries, regions} = action.payload;
      return {
        ...state,
        regions: generateRegionsObject(regionsCountries, regions),
        status: statuses.LOADED,
      };
    }

    case REGION_FILTER + SET_REGION: {
      const id = action.payload;
      const selected = state.regions.filters[id];
      const deselectBelow = (obj, id, shouldShow = true) => {
        obj.filters[id].checked = false;
        obj.filters[id].selected = false;
        obj.filters[id].show = shouldShow;
        obj.filters[id].selectedChildren = [];
        if (!obj.filters[id].parent || (obj.filters[id].parent && obj.filters[obj.filters[id].parent].selectedChildren.length > 0)) {
          obj.numSelected -= 1
        }
        const children = obj.filters[id].children
        for (let i = 0; i < children.length; i++) {
          deselectBelow(obj, children[i], false)
        }
      }

      const updatedRegions = {
        ...state.regions,
        filters: {
          ...state.regions.filters,
          [id]: {
            ...selected,
            checked: !selected.checked,
          },
        }
      }

      if (selected.selected || selected.checked) {
        if (selected.parent) {
          const parent = updatedRegions.filters[selected.parent]
          parent.selectedChildren = parent.selectedChildren.filter(sc => sc !== id)
          parent.checked = parent.selectedChildren.length === 0
        }
        deselectBelow(updatedRegions, id)
      } else {
        selected.children.forEach(c => {
          updatedRegions.filters[c].show = true
        })
        updatedRegions.numSelected += 1
        if (selected.parent) {
          const parent = updatedRegions.filters[selected.parent]
          parent.selectedChildren.push(id)
          if (parent.checked) {
            parent.checked = false
            parent.selected = true
            updatedRegions.numSelected -= 1
          }
        }
      }

      return {
        ...state,
        regions: updatedRegions,
      }
    }

    case REGION_FILTER + RESET_REGIONS: {
      const {regionsCountries} = action.payload;
      return {
        ...state,
        regions: generateRegionsObject(regionsCountries),
      };
    }

    default:
      return state;
  }
};
