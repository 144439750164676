import axios from "axios";

const axiosInstance = axios.create({
  timeout: 10000
});

const environment = process.env.REACT_APP_ENV;

let prevHash = undefined;
let versionCheckerInterval;

export const newVersionChecker = () => {
  if (environment !== 'development' && !versionCheckerInterval) {
    versionCheckerInterval = setInterval(() => {
      checkNewVersion();
    }, 60000);
  }
};

const checkNewVersion = () => {
  axiosInstance.get(`/app/portal/sha.txt?t=${Date.now()}`)
    .then(response => {
      const hash = response.data;
      if (prevHash !== undefined && hash !== prevHash) {
        window.location.reload();
      }
      prevHash = hash;
    })
    .catch(err => {
      // Offline ?
    });
};
