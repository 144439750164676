import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
//import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App/>, document.getElementById('root'));

/*
const onUpdate = (registration) => {
  console.log('onUpdate', registration);
};

serviceWorker.register({
  onUpdate: onUpdate
});
 */